.company-record {
  display: flex;
  justify-content: space-between;
  background: #e4e4e4;
  width: 230px;
  height: 120px;
  padding: 10px;
  margin-bottom: 50px;
  margin-right: 50px;
  border-radius: 15px;
  font-family: $font-family-medium;
  font-size: 16px;
  color: $colour_grayscale_darkest;
  align-items: center;

  .company-top-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    button {
      margin-bottom: 5px;
    }
  }

  .company-top-details {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-right: 15px;
    margin-left: 10px;
    font-size: 14px;
    flex-grow: 1;

    .username {
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: center;
      overflow-wrap: break-word;
      word-break: break-word;
    }

    .fullname {
      margin-top: 10px;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100px;
      overflow: hidden;
      display: none;
    }

    .initials {
      display: flex;
      flex-shrink: 0;
      flex-grow: 0;
      align-items: center;
      justify-content: center;
      background: $colour_grayscale_light;
      width: 60px;
      height: 60px;
      margin-right: 5px;
      margin-bottom: 5px;
      border: 2px solid $colour_grayscale_dark;
      border-radius: 50%;
      font-size: 30px;
      font-weight: bold;
      color: $colour_grayscale_darkest;
    }
  }

  .company-bottom-details {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    display: none;
  }
}
