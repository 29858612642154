.chatbot-page {
  p {
    color: #0b0b0b;
  }

  #webchat {
    box-shadow: 1px 1px 6px rgb(0 0 0 / 16%);

    .ac-pushButton {
      border-radius: 5px;
      color: #00643c;
      cursor: pointer;

      &[aria-pressed="true"] {
        background-color: #00643c;
        color: white;
      }
    }

    input {
      margin-bottom: 0;
    }

    .webchat__basic-transcript {
      max-height: 419px;
    }
  }
}
