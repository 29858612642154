.overdue-invoices-page {
  .floating-panel {
    margin-bottom: 20px;

    p {
      margin-block-start: 10px;
      margin-right: 25px;
      margin-block-end: 0;
      color: #6e6e82;
      font-size: 15px;
      font-family: $font-family-book;
    }

    .pagination-controls {
      font-size: 13px;
      font-family: $font-family-medium;
      color: #4d4f5c;
      width: 180px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      button {
        background-color: #ebeaea;
        font-weight: bold;
        color: #c3c3c3;
        border: none;
        border-radius: 3px;
        height: 30px;
        width: 30px;
        margin-left: 5px;
        font-size: 20px;
        cursor: pointer;

        &:disabled {
          cursor: default;
          color: #f0f0f0;
        }
      }
    }
  }

  .form-container {
    margin-bottom: 20px;
  }

  .overdue-invoices-container {
    .invoice {
      background-color: $colour-grayscale-white;
      border-radius: 25px;
      margin-bottom: 20px;
      padding: 18px 24px;

      .invoice-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        h4 {
          margin-block-start: 0;
          margin-block-end: 0;

          &:first-child {
            margin-right: 15px;
          }
        }
      }

      .invoice-body {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 10px;

        > div {
          width: 32%;

          @media only screen and (max-width: 900px) {
            width: 100%;
          }
        }

        .label-value-pair {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #7f7f7f;

          label {
            width: auto;
          }

          span {
            text-align: right;
          }
        }
      }
    }
  }
}
