.edit-user-page {
  &.connect-config {
    h5 {
      margin-bottom: 20px;
      margin-top: 0;
    }

    .header {
      display: flex;
      justify-content: space-between;

      button {
        margin-left: 10px;
      }
    }

    .module {
      &.top {
        margin-top: 10px;
        font-weight: bold;
      }

      &.nested {
        margin-left: 20px;
      }
    }

    form {
      display: flex;
      flex-direction: column;

      .horizontal-layout {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }

      .vertical-layout {
        margin-right: 10px;
        flex-grow: 1;
      }

      .edit-user-user-container {
        .panel-header {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          margin-bottom: -25px;
        }

        .initials {
          display: flex;
          align-items: center;
          justify-content: center;
          background: $colour_grayscale_light;
          width: 100px;
          height: 100px;
          border-radius: 50%;
          margin-right: 10px;
          font-size: 40px;
          font-weight: bold;
          color: $colour_grayscale_darkest;
        }
      }

      .edit-user-group-container {
        label {
          display: flex;
        }
      }

      .edit-user-accessrights-container {
        flex-grow: 1;

        .access-header {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;
        }

        .access-record {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          padding-bottom: 10px;
          border-bottom: 1px solid $colour_grayscale_light;

          &:last-child {
            border: none;
          }
        }
      }

      .user-fields {
        display: flex;
        flex-direction: column;
        align-items: unset;

        h3 {
          margin-block-end: 0;
          color: #6e6e82;
          font-size: 20px;
          font-weight: normal;
          font-family: "Roboto", sans-serif;
        }

        .user-field-block {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: flex-start;

          & > * {
            @media only screen and (max-width: 560px) {
              width: 100%;
            }

            margin-left: 10px;
          }
        }

        .personal-notes {
          width: 100%;

          textarea {
            width: 100%;
          }
        }
      }
    }
  }
}

.pie-and-values-wrapper {
  display: flex;
  flex-wrap: wrap;

  .user-values {
    margin-right: 20px;

    .user-value-item {
      display: flex;
      justify-content: space-between;

      .percentage-wrapper {
        display: flex;

        .percentage {
          margin-left: 10px;
          margin-top: 8px;
        }
      }

      label {
        width: 150px;
      }

      span {
        font-weight: bold;
        width: 42px;
        margin-top: 8px;
        margin-left: 10px;
      }

      input {
        width: 100px;
      }
    }
  }
}
