@import '../../../style/variables';

.in-full-dashboard {
  width: 100%;

  .section-filter {
    display: flex;
  }

  .floating-panel {
    padding: 10px 15px;

    .floating-header {
      display: block;
      margin-right: 0;

      @media screen and (max-width: 991px) {
        display: block;
      }

      .description {
        margin: 25px;
      }
    }
  }

  .filter-wrapper {
    width: 100%;
    margin-top: 10px;
    // width: 650px;
    // @media screen and (max-width: 991px) {
    //   width: 100%;
    // }
  }

  .filter-action {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 10px;

    .action {
      &.default {
        background-color: #fff;
        color: #4d4f5c;
        margin-left: 5px;
        border: none;
      }

      &.export {
        background-color: #4d4f5c;
        margin-right: 5px;
      }
    }
  }

  .title-container {
    display: flex;
    justify-content: space-between;

    .key {
      display: flex;
      justify-content: space-around;
      align-items: center;

      .key-item {
        display: flex;
        align-items: center;

        .green {
          height: 15px;
          width: 15px;
          background-color: #0ed988;
          margin-left: 12px;
          margin-right: 4px;
        }

        .amber {
          height: 15px;
          width: 15px;
          background-color: #faad00;
          margin-left: 12px;
          margin-right: 4px;
        }

        .red {
          height: 15px;
          width: 15px;
          background-color: #ed4646;
          margin-left: 12px;
          margin-right: 4px;
        }
      }
    }
  }

  .breakdown-title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 45px;
    margin: 20px 0;

    button {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 5px;
      background-color: #f5f5f9;
      color: #00643c;
      border: none;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      padding: 12px 20px;
      cursor: pointer;
      margin-right: 10px;
    }

    h3 {
      color: #6e6e82;
      font-size: 22px;
      font-family: "Roboto", sans-serif;
      font-weight: normal;
      margin: 0;
    }
  }

  .percentage-piechart {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: auto;
    margin-bottom: 20px;

    .percentage-piechart-inner {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;

      .chart {
        position: relative;
        max-width: 220px;
        height: auto;
        background: none;
        background-color: white;
        box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.16);
        border-radius: 15px;
        margin-right: 25px;
        margin-bottom: 20px;
        display: flex;
        cursor: pointer;
        align-items: center;
        flex-direction: column;
        padding: 20px;

        &.selected {
          border: 2px solid #4d4f5c;
        }

        .pie-percentage {
          position: absolute;
          margin: auto;
          top: 43%;
        }

        svg.recharts-surface {
          cursor: pointer;
        }

        .recharts-wrapper {
          transform: rotate(-90deg);
        }

        .recharts-tooltip-wrapper {
          transform: rotate(90deg) !important;
        }
      }
    }
  }

  .percentage-barchart {
    display: flex;
    justify-items: center;
    flex-direction: column;
    width: 100%;
    background-color: white;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.16);
    border-radius: 15px;
    margin-bottom: 39px;
    padding: 40px;

    p {
      text-align: center;
      margin-bottom: 0;
      font-weight: 600;
    }

    svg.recharts-surface {
      padding: 10px;
    }
  }

  .failed-items-table {
    .table-header {
      min-height: 100px;
    }
  }

  &.export-mode {
    background: none;
    background-color: white;

    .floating-panel,
    .table-container {
      background: none;
      background-color: white;
      box-shadow: none;
    }

    .percentage-piechart-inner {
      flex-wrap: wrap;
      overflow: none;
    }

    .chart,
    .percentage-piechart,
    .percentage-barchart {
      box-shadow: none;
    }

    .percentage-piechart .percentage-piechart-inner .chart {
      box-shadow: none;
    }

    .filter-wrapper,
    .filter-action,
    .table-header,
    #backButton {
      display: none;
    }
  }
}

button {
  outline: none;
}

.selected-filter {
  display: none;
}

.kpi-filter {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;

  .placeholder-label {
    visibility: hidden;
    opacity: 0;
  }

  label {
    // width: 24%;
    width: 23%;
    min-width: 200px;
    line-height: normal;
    margin-right: 15px;

    select {
      width: 100%;
      margin: 0 !important;
    }
  }

  &:last-child {
    label {
      margin-top: 0;
      margin-bottom: 0;

      select {
        margin-bottom: 0;
      }
    }
  }

  @media screen and (max-width: 991px) {
    justify-content: start;

    .placeholder-label {
      display: none;
    }

    label,
    button {
      width: 31%;
      min-width: unset;
    }

    label {
      order: 1;
    }

    button {
      order: 2;
    }
  }

  @media screen and (max-width: 700px) {
    label,
    button {
      width: 47%;
    }
  }

  @media screen and (max-width: 550px) {
    label,
    button {
      width: 100%;
    }
  }

  .dropdown-container {
    // height: 35px;
    // background: white;
    // color: #6f6f83;
    // border-radius: 15px;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-weight: bold;
    // margin-bottom: 0;
    padding: 0 15px;
    // border: 2px solid white;
    .dropdown-content {
      label {
        width: 100%;
      }
    }
  }

  .multi-select {
    // --rmsc-selected: #4285f4;
    --rmsc-border: white;
    --rmsc-bg: white;
    --rmsc-gray: #6f6f83;
    --rmsc-p: 10px; /* Spacing */
    --rmsc-radius: 15px; /* Radius */
    --rmsc-h: 35px; /* Height */
  }
}

.text-center {
  text-align: center !important;
}
