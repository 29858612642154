.item-catalogue-page {
  tbody {
    tr {
      td {
        &:first-child {
          font-family: $font-family-medium;
        }

        cursor: pointer;

        &:last-child {
          text-align: right;
        }
      }
    }
  }

  .item-catalogue-header {
    display: flex;
    flex-wrap: wrap;

    @media only screen and (max-width: 560px) {
      margin-left: 0;
      width: 100%;
    }

    select {
      margin-bottom: 15px;

      @media only screen and (max-width: 560px) {
        margin-left: 0;
        width: 100%;
      }
    }

    .header-buttons {
      display: flex;
      flex-wrap: nowrap;
      flex-grow: 1;
      justify-content: space-between;

      @media only screen and (max-width: 560px) {
        flex-direction: column;
      }

      .export {
        display: flex;
        justify-content: center;

        @media only screen and (max-width: 560px) {
          margin-bottom: 15px;
          margin-left: 0;
        }

        .icon {
          width: 25px;
          height: 25px;
          margin-top: 3px;
        }
      }

      .search {
        display: flex;
        flex-wrap: wrap;

        @media only screen and (max-width: 560px) {
          margin-left: 0;
          width: 100%;
        }

        input,
        button.action {
          margin-bottom: 15px;

          @media only screen and (max-width: 560px) {
            margin-left: 0;
            width: 100%;
          }
        }
      }
    }
  }
}
