.connect-events {
  flex-grow: 1;
  height: calc(100vh - 356px);
  padding: 10px;

  .date-block {
    margin-top: 20px;
    background-color: white;
    border-radius: 6px;
    padding: 20px;

    .date {
      font-size: 20px;
      margin-bottom: 10px;
    }
  }

  .radiuspanel {
    label {
      color: white;
    }
  }

  .error-stack {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 170px;
    color: white;
    height: calc(100vh - 300px);

    button {
      margin: 15px;
    }
  }

  .event-stack {
    flex-grow: 1;
    height: 100%;
  }

  .event-instance {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;

    .event-distance,
    .start-time {
      background-color: $colour_status_2;
      border-radius: 10px;
      padding: 10px;
      height: 60px;
      width: 60px;
      display: flex;
      text-align: center;
      align-items: center;
      flex-shrink: 0;
    }

    .start-time {
      background-color: $colour_status_8;
    }

    .event-details {
      overflow: hidden;
      word-break: keep-all;
      text-overflow: ellipsis;
      white-space: nowrap;
      align-self: flex-start;
      padding-left: 10px;
      padding-right: 10px;
      flex-grow: 1;

      div {
        overflow: hidden;
        word-break: keep-all;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
