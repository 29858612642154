.groups-page {
  .group-container {
    display: flex;
    flex-direction: rows;
    flex-wrap: wrap;
  }

  .group-header {
    display: flex;
    justify-content: space-between;

    button {
      margin-left: 10px;
    }
  }

  .floating-panel {
    display: flex;
    flex-direction: column;
    background:
      transparent linear-gradient(180deg, #f4f5fa 0%, #eeecec 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.16);
    border-radius: 25px;
    padding: 36px;
    transition-duration: 0.3s;

    .space-holder {
      width: 160px;
    }

    .floating-header {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      margin-right: 50px;

      @media only screen and (max-width: 800px) {
        margin-right: 30px;
      }

      @media only screen and (max-width: 560px) {
        margin-right: 0;
      }

      margin-bottom: 20px;

      .header-title {
        display: flex;
        align-items: center;
      }

      .icon {
        height: 100px;
        width: 100px;
      }

      h2 {
        margin-block-end: 20px;
        color: #6e6e82;
        font-size: 22px;
        font-family: $font-family-medium;
      }
    }
  }
}
