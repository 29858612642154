.menu {
  background-color: $colour_grayscale_white;
  width: 392px;
  height: 100vh;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 100vh;
  z-index: 20;
  position: relative;
  transition-duration: 0.3s;

  @media only screen and (max-width: 1560px) {
    position: absolute;
  }

  .side-menu-holder {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .logo {
      background-image: url("/assets/clear-logo.png");
      height: 99px;
      width: 99px;
      background-repeat: no-repeat;
      background-size: cover;
      margin-top: 28px;
      margin-left: 44px;
      flex-shrink: 0;
      cursor: pointer;
    }

    .close-button {
      font-weight: bold;
      cursor: pointer;
      color: #4d4f5c;
      font-size: 24px;
      margin-right: 22px;
      margin-top: 22px;
    }
  }

  .notifications {
    overflow: hidden;
    margin-left: 44px;
    margin-right: 32px;
  }

  .menu-footer {
    display: flex;
    flex-direction: column;
    height: 96px;
  }

  .welcome-all {
    margin-top: 38px;
    margin-left: 44px;

    h2 {
      font-size: 24px;
      margin-bottom: 10px;
      color: #4d4f5c;
    }
  }

  &.closed {
    width: 0;

    .logo {
      display: none;
    }

    .close-button {
      display: none;
    }

    .notifications {
      display: none;
    }

    .welcome-all {
      display: none;
    }
  }
}
