@import '../../../../style/variables.scss';

.progress-state {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;

  .progress-update {
    flex-grow: 1;
    flex-shrink: 1;
    margin-left: 0;
    margin-right: 10px;
    border-radius: 5px;
    padding: 5px;
    border: 1px solid black;
    color: transparent;

    &:last-child {
      margin-right: 0;
    }

    &.progressed {
      visibility: visible;
      background-color: $colour_good;
      color: black;
      text-align: center;
    }
  }
}
