.whatissiteid-page {
  .close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  h3 {
    font-size: 20px;
  }

  background: white;

  .mid-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .top-content {
    display: flex;
    flex-wrap: wrap;
    z-index: 10;
    max-width: 1000px;
    min-height: 420px;

    .introduction {
      margin-left: 20px;
      width: 300px;
      margin-top: 50px;

      .starbucks-logo {
        width: 50px;
        height: 50px;
        margin-bottom: 30px;
      }

      h3 {
        font-size: 18px;
      }

      button {
        width: 120px;
        height: 30px;
      }
    }

    .image-holder {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 50px;

      iframe {
        position: absolute;
        left: 113px;
        top: 50px;
        width: 448px;
        height: 289px;

        @media only screen and (max-width: 680px) {
          width: calc(100% - 234px);
          left: 114px;
          height: 70%;
          top: 35px;
        }

        @media only screen and (max-width: 600px) {
          width: calc(100% - 212px);
          left: 105px;
          height: 70%;
          top: 35px;
        }

        @media only screen and (max-width: 540px) {
          width: calc(100% - 204px);
          left: 100px;
          height: 66%;
          top: 40px;
        }

        @media only screen and (max-width: 540px) {
          width: calc(100% - 194px);
          left: 94px;
          height: 66%;
          top: 40px;
        }

        @media only screen and (max-width: 490px) {
          display: none;
        }
      }

      .laptop-image {
        width: 680px;
        margin: 0 auto;
        margin-top: 30px;

        @media only screen and (max-width: 680px) {
          width: calc(100% - 40px);
        }
      }
    }

    @media only screen and (max-width: 1000px) {
      flex-direction: column;
    }
  }

  .key-features {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $colour_SBgreen_dark;
    background-image:
      url("/assets/SmallDotTexture.svg"),
      url("/assets/largedots2.svg");
    background-repeat: no-repeat, no-repeat;
    background-position: top -200px center, top -280px right 75px;
    width: 100%;
    margin-top: -150px;
    padding-top: 180px;
    padding-bottom: 150px;

    .key-features-content {
      max-width: 1000px;

      @media only screen and (max-width: 1000px) {
        max-width: 800px;
      }

      color: white;

      h3 {
        margin-left: 20px;
      }

      h3,
      h4,
      p {
        color: white;
      }

      .icons-and-descriptions {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;

        .feature {
          display: flex;
          align-items: center;
          flex-shrink: 0;

          @media only screen and (max-width: 1000px) {
            width: 100%;
          }

          .feature__text {
            display: flex;
            flex-direction: column;
            width: 220px;

            @media only screen and (max-width: 1000px) {
              width: 50%;
            }

            h4,
            p {
              margin-top: 5px;
            }
          }

          &.feature-long {
            .feature__text {
              width: 565px;

              @media only screen and (max-width: 1000px) {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  .access-on-the-go {
    max-width: 1000px;
    display: flex;
    justify-content: space-between;
    margin-top: -50px;
    margin-bottom: -50px;

    @media only screen and (max-width: 1000px) {
      margin-top: 50px;
      flex-wrap: wrap-reverse;
    }

    .access-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-grow: 1;
      padding-bottom: 50px;
      margin-left: 50px;
    }

    .phone-image {
      width: 300px;
      z-index: 300;
      position: relative;
      flex-shrink: 0;
      margin: 0 auto;
    }
  }

  .simple-to-use {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #e4e4e4;
    height: 800px;
    width: 100%;
    margin-top: -50px;
    z-index: 2;
    position: relative;
    border-top: 1px solid grey;
    background-image: url("/assets/SmallDotTexture2.svg");
    background-position: 250px -300px;
    background-size: 700px 700px;
    background-repeat: no-repeat;

    h3 {
      margin-top: 100px;
      display: flex;
      justify-content: center;
      align-content: top;
    }

    .simple-content {
      display: flex;
      width: 100%;
      max-width: 1000px;

      .simple-green-box {
        display: flex;
        flex-direction: column;
        margin-top: 150px;
        flex-shrink: 0;
        flex-grow: 0;
        width: 450px;
        height: 300px;
        margin-right: -100px;

        @media only screen and (max-width: 765px) {
          margin-right: -200px;
        }

        @media only screen and (max-width: 450px) {
          width: 300px;
        }

        z-index: 5;
        position: relative;
        background-color: $colour_SBgreen_dark;
        justify-content: center;
        align-items: center;
        text-align: center;

        .icon {
          margin-top: 30px;
          height: 60px;
        }

        p {
          color: white;
          margin: 10px 30px 30px 30px;
        }
      }

      .simple-image {
        z-index: 4;
        position: relative;
        margin-left: -50px;
        height: 450px;
        margin-top: 70px;
      }
    }
  }

  .store-showcase {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("/assets/whatissiteid/starbucks_t5.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 700px;
    width: 100%;
    border-top: 50px solid $colour_SBgreen_dark;

    .store-green-box {
      display: flex;
      flex-direction: column;
      height: 300px;
      width: 500px;
      background-color: $colour_SBgreen_dark;
      justify-content: center;
      justify-self: center;
      align-items: center;
      text-align: center;

      .icon {
        margin-top: 30px;
        height: 60px;
      }

      p {
        color: white;
        margin: 10px 30px 30px 30px;
      }
    }
  }

  .includes {
    background-color: #e4e4e4;
    padding-top: 75px;
    padding-bottom: 100px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-self: center;
    align-items: center;
    text-align: center;

    h3 {
      font-size: 20px;
    }

    .tiles {
      display: flex;
      justify-content: flex-start;
      max-width: calc(100% - 40px);
      margin-left: 20px;
      margin-right: 20px;
      overflow: auto;

      .tile {
        width: 350px;
        flex-shrink: 0;
        margin-right: 20px;
      }
    }
  }

  .access-today {
    display: flex;
    width: 100%;
    justify-content: center;
    align-content: center;

    .access-content {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      max-width: 1000px;

      @media only screen and (max-width: 1000px) {
        flex-wrap: wrap-reverse;
      }

      .access-text {
        display: flex;
        align-content: center;
        flex-direction: column;
        flex-shrink: 1;
        justify-content: center;

        @media only screen and (max-width: 765px) {
          width: 100%;
          text-align: center;
          align-items: center;
        }

        button {
          margin-top: 20px;

          @media only screen and (max-width: 765px) {
            width: 80%;
            text-align: center;
          }
        }
      }

      .i-pad-image {
        width: 700px;
        margin: 0 auto;
        z-index: 300;
        position: relative;
        flex-shrink: 0;

        @media only screen and (max-width: 700px) {
          width: 100%;
          text-align: center;
        }
      }
    }
  }
}
