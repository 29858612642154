.whatisorderly-page {
  background-color: white;
  overflow: hidden;

  .right-align {
    text-align: right;
  }

  button.float {
    &--blue {
      background-color: #3ac1d4;
      height: 30px;
      border-radius: 10px;
    }
  }

  .blue-box {
    background-color: #3ac1d4;
    padding: 60px 90px;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;

    p {
      color: white;
      text-align: center;
      font-size: 22px;
      line-height: 30px;
    }
  }

  .orderly-menu {
    background-color: #3ac1d4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px;
    padding-left: 50px;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 100;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.16);

    .button-bar {
      @media only screen and (max-width: 1175px) {
        display: none;
      }
    }

    a {
      color: white;
      font-weight: normal;
      font-size: 22px;
    }

    .close-button {
      cursor: pointer;
    }
  }

  .top-content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-top: 230px;
    margin-bottom: -110px;

    .introduction {
      width: 400px;

      @media only screen and (max-width: 1175px) {
        width: 80%;
        margin-bottom: 35px;
      }
    }

    img {
      width: 700px;

      @media only screen and (max-width: 1175px) {
        width: 100%;
        margin-bottom: -110px;
      }
    }
  }

  #key-features {
    max-width: 1100px;
    margin: 0 auto;
    padding: 150px 0;

    @media only screen and (max-width: 1175px) {
      padding-top: 200px;
      max-width: 800px;
      margin-left: 25px;
    }

    p,
    h3,
    h4 {
      color: white;
      margin-left: 25px;
    }

    .feature-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      @media only screen and (max-width: 850px) {
        flex-direction: column;
        margin-left: 25px;
      }
    }

    .feature {
      display: flex;
      align-items: center;
      flex-shrink: 0;

      @media only screen and (max-width: 1000px) {
        width: 100%;
      }

      .feature__text {
        display: flex;
        flex-direction: column;
        width: 250px;

        @media only screen and (max-width: 1000px) {
          width: 90%;
        }

        h4,
        p {
          margin-top: 5px;
          line-height: 30px;
        }
      }
    }
  }

  #proven {
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: -120px;
    margin-bottom: -140px;

    @media only screen and (max-width: 1095px) {
      margin-bottom: 0;
      flex-direction: column;
    }

    .access-text {
      width: 700px;

      @media only screen and (max-width: 800px) {
        width: 100%;
        padding: 35px;
        box-sizing: border-box;
      }

      p {
        font-size: 19px;
        line-height: 28px;
      }
    }
  }

  #award-winning {
    background-color: #e4e4e4;
    padding-top: 130px;
    padding-bottom: 80px;
    background-image:
      url("/assets/SmallDotTexture2.svg"),
      url("/assets/SmallDotTexture2.svg");
    background-repeat: no-repeat, no-repeat;
    background-position: top -300px center, bottom -600px center;

    h3 {
      text-align: center;
    }

    .simple-content {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 60px;
      padding-top: 80px;
      align-items: center;

      @media only screen and (max-width: 1050px) {
        flex-direction: column;
        align-items: center;
      }

      .blue-box {
        width: 25%;
        margin-right: -100px;

        @media only screen and (max-width: 1500px) {
          width: 50%;
          margin-right: -300px;
          margin-left: 25px;
        }

        @media only screen and (max-width: 1050px) {
          width: 70%;
          margin-right: 50px;
          margin-left: 50px;
          margin-bottom: -60px;
        }

        @media only screen and (max-width: 800px) {
          margin-bottom: -30px;
        }
      }

      img {
        width: 50%;

        @media only screen and (max-width: 1500px) {
          width: 75%;
          align-self: flex-end;
        }

        @media only screen and (max-width: 800px) {
          width: 90%;
          align-self: flex-end;
        }
      }
    }
  }

  #highly-rated {
    padding: 60px 0;

    .store {
      background-image: url("/assets/SBuxMilanPressRelease.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      height: 1000px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .blue-box {
        max-width: 475px;

        p {
          &:last-child {
            font-weight: bold;
          }
        }
      }
    }
  }

  #faq {
    background-color: #e4e4e4;
  }

  #contact-us {
    max-width: 1100px;
    flex-wrap: wrap;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -60px;
    margin-bottom: -140px;

    @media only screen and (max-width: 1080px) {
      flex-direction: column-reverse;
      margin-bottom: 35px;
    }

    .access-text {
      width: 450px;

      @media only screen and (max-width: 1080px) {
        padding: 35px;
      }

      @media only screen and (max-width: 700px) {
        width: 100%;
        box-sizing: border-box;
      }

      p {
        font-size: 19px;
        line-height: 28px;
        margin-right: 30px;
      }

      a {
        margin: 0;
        padding: 0;
      }

      button.float {
        width: auto;
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    img {
      @media only screen and (max-width: 700px) {
        width: 90%;
      }
    }
  }

  .bar {
    &--blue {
      background-color: #3ac1d4;
    }

    &--decorations {
      background-image:
        url("/assets/the_wave.svg"),
        url("/assets/SmallDotTexture.svg"),
        url("/assets/largedots.svg");
      background-repeat: no-repeat, no-repeat, no-repeat;
      background-position:
        bottom -130px right -476px,
        top -200px center,
        top -345px right 42px;
    }
  }

  .icon {
    &.icon-recipe {
      background-image: url("/assets/orderly/icons/recipes.svg");
      height: 100px;
    }

    &.icon-smarter {
      background-image: url("/assets/orderly/icons/smarter.svg");
      height: 100px;
    }

    &.icon-transfers {
      background-image: url("/assets/orderly/icons/transfers.svg");
      height: 100px;
    }

    &.icon-rmas {
      background-image: url("/assets/orderly/icons/RMAs.svg");
      height: 100px;
    }

    &.icon-dashboard {
      background-image: url("/assets/orderly/icons/dashboard.svg");
      height: 100px;
    }

    &.icon-reporting {
      background-image: url("/assets/orderly/icons/analytics.svg");
      height: 100px;
    }

    &.icon-po {
      background-image: url("/assets/orderly/icons/PO.svg");
      height: 100px;
    }

    &.icon-gears {
      background-image: url("/assets/orderly/icons/gearswithticksin.svg");
      height: 100px;
    }

    &.icon-success {
      background-image: url("/assets/orderly/icons/success.svg");
      height: 250px;
      width: 250px;
      margin: 0 auto;
      margin-top: 25px;
      margin-bottom: 25px;
    }

    &.icon-mobile {
      background-image: url("/assets/orderly/icons/mobile.svg");
      height: 100px;
    }

    &.icon-trophy {
      background-image: url("/assets/orderly/icons/trophy.svg");
      height: 100px;
    }
  }
}
