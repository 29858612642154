.site-id-page {
  .floating-header {
    padding: 0;
    margin: 0;
    margin-bottom: 0;
    margin-right: 0;

    .horizontal-controls {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }
  }

  text.inner-label {
    font-weight: lighter;
  }
}
