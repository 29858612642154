.pending-mdio-creation-page {
  .search-wrapper {
    display: flex;

    input {
      margin-right: 15px;
    }
  }

  .downloads {
    margin: 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .all-open-requests-header {
    background-color: #4d4f5c;
    color: white;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    max-width: 340px;

    button.download {
      margin-left: 10px;
      width: 60px;
      height: 34px;
      font-weight: normal;
      font-size: 16px;
      border-radius: 17px;
      border: none;
      font-family: "Roboto", sans-serif;
      transition-duration: 0.3s;
      color: white;
      background-color: #02a862;
      box-shadow: 0 3px 6px #00000029;
      cursor: pointer;
      background-image: url("/assets/icons/Download light small.svg");
      background-size: 22px;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .bulk-update {
    display: flex;
    flex-wrap: wrap;
    margin-left: 16px;
  }

  button.bulk-download {
    margin-top: 18px;
    margin-left: 10px;
    margin-right: 10px;
    width: 155px;
    height: 34px;
    font-weight: normal;
    font-size: 16px;
    border-radius: 17px;
    border: none;
    font-family: "Roboto", sans-serif;
    transition-duration: 0.3s;
    color: white;
    background-color: #02a862;
    box-shadow: 0 3px 6px #00000029;
    cursor: pointer;
  }

  .bulk-update-text {
    max-width: 300px;
  }
}
