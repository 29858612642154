.forecast-page {
  h2 {
    color: #4d4f5c;
    font-size: 24px;
    font-family: $font-family-medium;
    margin-block-end: 20px;
    margin-block-start: 0;
  }

  .menuContainer {
    display: flex;
    flex-wrap: wrap;
  }

  .floating-panel {
    display: flex;
    flex-direction: column;
    background:
      transparent linear-gradient(180deg, #f4f5fa 0%, #eeecec 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.16);
    border-radius: 25px;
    padding: 36px;
    padding-bottom: 0;
    opacity: 0;
    transition-duration: 0.3s;

    @media only screen and (max-width: 735px) {
      padding-right: 11px;
      padding-left: 11px;
    }

    &.loaded {
      opacity: 1;
    }

    .floating-header {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;

      h2 {
        margin-block-end: 0;
        margin-block-start: 0;
        color: #6e6e82;
        font-size: 22px;
        font-family: $font-family-medium;
      }

      p {
        margin-block-start: 10px;
        margin-block-end: 0;
        color: #6e6e82;
        font-size: 15px;
        font-family: $font-family-book;
      }
    }
  }

  .menu-container {
    border: #121213 20px;
    margin-bottom: 120px;
  }

  .dashboard-title {
    text-align: center;
    color: #4d4f5c;
    font-size: 24px;
    font-family: $font-family-medium;
    margin-block-end: 20px;
    margin-block-start: 0;
  }

  .dashboard-container {
    border: 2px solid #000;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #f8f8f8;
    margin-bottom: 50px;
  }
}

.forecast-export {
  margin-left: 80%;
  padding-left: 50px;
  background-color: inherit;
}

@media only screen and (max-width: 735px) {
  .forecast-export {
    margin-left: 40%;
    padding-left: 50px;
  }
}
