.notification-card2 {
  margin-bottom: 10px;
  margin-left: 24px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70vw;
  flex-shrink: 0;

  @media only screen and (max-width: 1020px) {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 70px;
    width: calc(90vw - 70px);
    position: relative;
  }

  button {
    background-color: #ffca83;
    box-shadow: none;
    color: white;
    font-weight: normal;
    flex-shrink: 0;
    margin-right: 20px;

    &.action {
      background-color: #02a862;
    }
  }

  &.not-acknowledged {
    border: none;
    position: relative;
    background-color: rgba(255, 202, 131, 0.2);
    border-radius: 10px;
    overflow: hidden;

    @media only screen and (max-width: 1020px) {
      padding: 5px;
      overflow: visible;
    }

    &::before {
      background-color: #ffca83;
      content: "New";
      color: white;
      font-family: $font-family-light;
      font-size: 13px;
      padding: 2px 20px;
      position: absolute;
      left: -16px;
      top: 8px;
      transform: rotate(-45deg);

      @media only screen and (max-width: 1020px) {
        content: none;
      }
    }
  }

  .left-holder {
    text-align: right;
    align-items: center;
    width: 265px;
    flex-shrink: 0;
    flex-grow: 0;

    @media only screen and (max-width: 1020px) {
      text-align: left;
      width: auot;
    }
  }

  .right-holder {
    flex-grow: 1;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 1020px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .message-holder {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .icon-holder {
    height: 38px;
    width: 38px;
    margin: 16px;
    border: 1px solid #ffca83;
    border-radius: 50%;
    flex-shrink: 0;

    @media only screen and (max-width: 1020px) {
      left: -65px;
      top: -15px;
      position: absolute;
    }

    &.admin {
      border: 1px solid #ff7285;
    }

    &.order-management {
      border: 1px solid #4bd991;
    }

    &.item-catalogue {
      border: 1px solid #3d8b99;
    }

    &.document-library {
      border: 1px solid #4b71d9;
    }

    &.quality-awareness {
      border: 1px solid #d9c44b;
    }

    &.pricing-catalogue {
      border: 1px solid #4bd991;
    }

    &.forecasting {
      border: 1px solid #4bd99e;
    }

    &.orderly-inventory {
      border: 1px solid #61c3df;
    }

    &.reports {
      border: 1px solid #26c066;
    }

    &.policies {
      border: 1px solid #bb61df;
    }

    .icon {
      height: 26px;
      width: 26px;
      position: relative;
      margin-right: 6px;
      margin-left: 6px;
      margin-top: 5px;
    }
  }

  h5.title {
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: 13px;
    color: #4d4f5c;
    font-weight: normal;
    font-family: $font-family-medium;
    margin-left: 16px;
  }

  .message {
    font-family: $font-family-book;
    font-size: 13px;
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .sent-on {
    opacity: 0.5;
    font-family: $font-family-light;
    font-size: 13px;
    margin-bottom: 6px;
  }
}
