
      
.th-style{
    background-color: #4d4f5c;
    color: white;
    height: 49px;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    padding: 10px;
    border-radius: 10px 0 0 10px;   
    text-align: center !important;
}

.text-style {
    text-align: center !important;
}

table tbody tr td {
    text-align: center !important;
}
