@import url("https://fonts.googleapis.com/css?family=Crete+Round&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");

$colour_grayscale_white: #fff;
$colour_grayscale_light: #f4f2f2;
$colour_grayscale_dark: #bbb;
$colour_grayscale_darkest: #707070;
$colour_grayscale_black: #292929;

$colour_highlight: #61c3df;
$colour_good: #0ed988;
$colour_bad: #ed4646;
$colour_SBgreen_light: #02a862;
$colour_SBgreen_dark: #00643c;

$font-family-medium: "Roboto", sans-serif;
$font-family-2: "Crete Round", sans-serif;
$font-family-bold: "Roboto", sans-serif;
$font-family-book: "Roboto", sans-serif;
$font-family-light: "Roboto", sans-serif;

$colour_status_1: #fff;
$colour_status_2: #ffcd47;
$colour_status_3: #faad00;
$colour_status_4: #3782df;
$colour_status_5: #6137df;
$colour_status_6: #ec6e6e;
$colour_status_7: #ec2a2a;
$colour_status_8: #02a862;
$colour_status_9: #707070;
$colour_status_10: #707070;
$colour_status_11: #707070;
$colour_status_12: #707070;
