.table-container {
  background:
    transparent linear-gradient(180deg, #f4f5fa 0%, #eeecec 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.16);
  border-radius: 25px;
  padding-bottom: 10px;

  .table-body {
    margin: 0 28px;
    margin-bottom: 28px;
    overflow: auto;
  }

  .table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 28px;
    position: relative;

    @media only screen and (max-width: 560px) {
      height: 145px;
    }

    h2 {
      padding-block-start: 30px;
      padding-block-end: 30px;
      color: #707070;
      font-size: 22px;
      font-family: $font-family-medium;

      @media only screen and (max-width: 560px) {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        text-align: center;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }

    .display-options {
      width: 180px;
      font-size: 13px;
      font-family: $font-family-medium;
      color: #4d4f5c;
      display: flex;
      align-items: center;

      @media only screen and (max-width: 560px) {
        margin-top: 70px;
      }

      button {
        height: 30px;
        width: 30px;
        background: none;

        &.icon-display-mode-1 {
          background-image: url("/assets/table-icons/Venti.svg");
        }

        &.icon-display-mode-2 {
          background-image: url("/assets/table-icons/Tall.svg");
        }

        &.icon-display-mode-3 {
          background-image: url("/assets/table-icons/Grande.svg");
        }

        &:disabled {
          opacity: 0.5;
        }
      }
    }

    .pagination-controls {
      font-size: 13px;
      font-family: $font-family-medium;
      color: #4d4f5c;
      width: 180px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @media only screen and (max-width: 560px) {
        margin-top: 70px;
      }

      button {
        box-shadow: 0 1px 3px #00000029;

        &:disabled {
          box-shadow: none;
        }
      }
    }

    button:not(.action) {
      background-color: #ebeaea;
      font-weight: bold;
      color: #c3c3c3;
      border: none;
      border-radius: 3px;
      height: 30px;
      width: 30px;
      margin-left: 5px;
      font-size: 20px;
      cursor: pointer;

      &:disabled {
        cursor: default;
        color: #f0f0f0;
      }
    }
  }
}
