.group-record {
  display: flex;
  justify-content: space-between;
  background: #e4e4e4;
  width: 250px;
  height: 120px;
  padding: 10px;
  margin-bottom: 50px;
  margin-right: 50px;
  border-radius: 15px;
  font-family: $font-family-medium;
  font-size: 14px;
  color: $colour_grayscale_darkest;

  .icon {
    height: 50px;
    width: 50px;
  }

  .group-top-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    button {
      width: 100px;
      margin-bottom: 5px;
    }
  }

  .group-top-details {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-right: 20px;
    flex-grow: 1;
    margin-left: 10px;
    justify-content: space-evenly;
    text-align: center;
  }

  .user-bottom-details {
    margin-top: 10px;
    text-align: center;
  }
}
