.connect-weather-tile-vertical {
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-radius: 5px;
  box-shadow: 1px 1px 6px -2px #989696;
  color: black;
  margin-bottom: 12px;

  .date {
    font-size: 20px;
    text-align: center;
    color: black;
  }

  .weather-row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    .left-stack {
      .weather {
        text-align: center;
      }
    }

    .temperatures {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;

      .min-max {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 100%;
      }

      .thermometer {
        width: 60px;
      }
    }
  }
}
