.template.template-2 {
  color: $colour_grayscale_black;

  .col-1,
  .col-3 {
    flex-grow: 0;
    flex-shrink: 0;
  }

  .col-2 {
    flex-grow: 1;
    flex-shrink: 1;
    margin: 48px;
  }

  h2.heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $colour_grayscale_black;
    text-align: center;
    font-size: 50px;
    line-height: 60px;
    font-weight: 400;
    text-transform: uppercase;
    margin-left: 48px;
  }

  h3.sub-heading {
    color: $colour_grayscale_black;
    font-weight: 400;
    margin: 0;
  }

  .content {
    color: $colour_grayscale_black;
    transition-duration: 0.3s;
    max-height: 550px;
    overflow: auto;

    .content-content {
      height: 0;
      overflow: hidden;
    }

    &.content-expanded {
      max-height: 550px;
      overflow: auto;

      .content-content {
        height: 100%;
        overflow: auto;
      }
    }

    p {
      color: unset;
    }

    a {
      padding: 0;
      color: unset;
      text-decoration: underline;
      font-weight: 500;
    }
  }

  .content-expander {
    margin-top: 15px;
  }
}
