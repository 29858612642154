.termswrapper {
  width: 100vw;
}
.terms-page {
  display: flex;
  flex-direction: column;
  padding: 50px;
  width: 100%;

  @media (max-width: 940px) {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    //width: calc(100% - 20px);
  }

  .banner-top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: $colour_SBgreen_dark;
    height: 0;
    z-index: 0;
    transition-duration: 0.3s;
    &.loaded {
      height: 200px;
    }
  }
  .banner-text {
    z-index: 1;
    opacity: 0;
    transition-duration: 0.3s;
    transition-delay: 0.3s;

    &.loaded {
      opacity: 1;
    }
    h5 {
      margin-top: 0px;
      margin-bottom: 20px;
      color: $colour_grayscale_white;
      z-index: 1;
      font-weight: normal;
    }
  }
  .form {
    z-index: 2;
  }
  .terms-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background: transparent linear-gradient(180deg, #f4f5fa 0%, #eeecec 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.16);
    padding: 20px 50px;
    font-size: 16px;
    overflow: hidden;
    border-radius: 25px;
    min-height: 82vh;
    opacity: 0;
    transition-duration: 0.3s;
    transition-delay: 0.6s;

    @media (max-width: 940px) {
      padding-left: 10px;
      padding-right: 10px;
      //width: calc(100% - 20px);
    }

    &.loaded {
      opacity: 1;
    }

    h4 {
      font-size: 16px;
      margin-top: 10px;
    }

    h2 {
      margin-top: 0px;
    }
    .logo {
      background-image: url("/assets/clear-logo.png");
      height: 90px;
      width: 90px;
      background-repeat: no-repeat;
      background-size: cover;
      flex-shrink: 0;
      align-self: center;
      justify-self: center;
      cursor: pointer;
    }
    sub {
      font-family: $font-family-book;
      font-size: 10px;
      color: $colour_grayscale_dark;
      margin-top: 10px;
    }
    p:first-of-type {
      margin: 0;
      padding: 0;
    }
    .terms-text {
      background: #e4e4e4;
      overflow: auto;
      height: 50vh;
      margin-top: 20px;
      padding: 20px 20px;
    }
    h5:first-of-type {
      margin: 0;
    }
    h5 {
      font-size: 14px;
    }
    p {
      font-size: 12px;
      margin: 8px;
      margin-left: 0px;
      font-weight: 100;
    }
  }
  .submission {
    display: flex;
    justify-content: center;
    button {
      margin-left: 20px;
      margin-top: 20px;
    }
  }
  ul li {
    font-family: $font-family-medium;
    font-weight: 100;
    font-size: 12px;
    color: #707070;
    line-height: 20px;
  }
}
