.fcr-detail-page {
  label {
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
  }

  .form-body {
    margin-bottom: 0;
    margin-top: 5px;
  }

  span {
    font-weight: 300;
    overflow-wrap: anywhere;
  }

  .large-text {
    width: 1000px;
    text-align: left;
  }
}

.fcr-management-page {
  span {
    border-radius: 3px;
  }

  .checkbox {
    margin-bottom: 0;
  }

  .status-rejected {
    background-color: $colour_bad;
    padding: 5px;
  }

  .status-approved {
    background-color: $colour_good;
    padding: 5px;
  }

  .status-pending {
    background-color: $colour_status_2;
    padding: 5px;
  }

  .bulk-edit-pagination-container {
    display: flex;
    flex-direction: column;

    .action {
      margin-top: 10px;
    }
  }
}
