.my-documents-page {
  .documents-container {
    display: flex;
    flex-direction: rows;
    flex-wrap: wrap;
  }

  .documents-header {
    display: flex;
    justify-content: space-between;

    button {
      margin-left: 10px;
    }
  }

  .floating-panel {
    display: flex;
    flex-direction: column;
    background:
      transparent linear-gradient(180deg, #f4f5fa 0%, #eeecec 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.16);
    border-radius: 25px;
    padding: 36px;
    transition-duration: 0.3s;
    justify-content: center;

    .space-holder {
      width: 160px;
    }

    .floating-header {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      margin-right: 20px;
      margin-bottom: 20px;

      @media only screen and (max-width: 560px) {
        margin-right: 0;
      }

      .header-title {
        display: flex;
        align-items: center;
      }

      .icon {
        height: 100px;
        width: 100px;
      }

      h2 {
        margin-block-end: 20px;
        color: #6e6e82;
        font-size: 22px;
        font-family: $font-family-medium;
      }
    }

    .folder-container {
      display: flex;
      flex-wrap: wrap;

      h4 {
        margin: 0;
        margin: 13px;
      }

      .folder {
        background-color: white;
        padding: 10px;
        border-radius: 5px;
        margin: 10px;

        .my-documents-holder {
          display: flex;
          flex-wrap: wrap;

          .file-container {
            min-width: 400px;
            width: auto;
            margin: 10px;
          }
        }
      }
    }

    .my-documents-holder {
      display: flex;
      flex-wrap: wrap;

      .file-container {
        @media only screen and (max-width: 1980px) and (min-width: 1426px) {
          width: calc(25% - 64px);

          &:nth-child(4n) {
            margin-right: 0;
          }
        }

        @media only screen and (max-width: 1425px) and (min-width: 1121px) {
          width: calc(33% - 58px);

          &:nth-child(3n) {
            margin-right: 0;
          }
        }

        @media only screen and (max-width: 1120px) and (min-width: 660px) {
          width: calc(50% - 43px);
          margin-right: 20px;

          &:nth-child(2n) {
            margin-right: 0;
          }
        }

        @media only screen and (max-width: 660px) {
          width: 100%;
          margin-right: 0;
          margin-bottom: 20px;
        }
      }
    }
  }
}
