.item-detail-page {
  img {
    border-radius: 5px;
    max-width: 330px;
    max-height: 125px;
    margin-right: 59px;
  }

  .image-placeholder {
    border: 2px dashed grey;
    border-radius: 5px;
    max-width: 330px;
    padding: 15px;
    margin-right: 59px;
  }

  .header-image {
    display: flex;
    align-items: center;

    h2 {
      margin-right: 42px;
    }
  }

  table {
    width: 330px;
    border-radius: 0;

    thead {
      border-radius: 0;

      tr {
        border-radius: 0;

        th {
          background: none;
          color: #7f7f7f;
          //border-left: 1px solid #7f7f7f;
          border-bottom: 1px solid #7f7f7f;
          border-radius: 0;
          padding: 1px;
          height: auto;
          text-align: center;

          span {
            transform: rotate(-51deg);
            display: block;
          }
        }
      }
    }

    tbody {
      tr {
        line-height: 30px;

        td {
          border-bottom: 1px solid #7f7f7f;
          text-align: center;
        }

        &:last-child {
          td {
            border-bottom: none;
          }
        }
      }
    }
  }

  ul {
    width: 290px;
  }

  .form-container .form-body {
    &.form-body-top {
      align-items: flex-start;
    }

    & > div {
      width: 330px;
    }
  }
}
