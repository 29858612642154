.flags {

  &.nflags{
    background:
      transparent linear-gradient(180deg, #faad00 0%, #faad00 100%) 0%
      0% no-repeat padding-box;
    color: white;
    font-weight: bold;
  }

  &.sflags{
    background:
      transparent linear-gradient(180deg, #e85b5d 0%, #e85b5d 100%) 0%
      0% no-repeat padding-box;
    color: white;
    font-weight: bold;
  }

  &.full-screen {
    position: absolute;
    z-index: 3;
    top: 50px;
    right: 10px;
    width: 410px;
  }
}
