
.equity-wrapper-priority {
  justify-content: flex-start;
  margin: 8px;
  margin-top: 0;
  width: 650px;
  background: transparent linear-gradient(180deg, #f4f5fa 0%, #eeecec 100%) 0% 0% no-repeat padding-box;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.16);
  border-radius: 25px;
  position: absolute;
  z-index: 3;
  bottom: 0;
  left: 0;
  flex-wrap: wrap;

  .key-header-priority {
    margin: 10px;
    position: absolute;
    left: 10px;
    bottom: 10px;

    .close-button,
    .open-button {
      height: 24px;
      width: 24px;
      border-radius: 30px;
      font-size: 16px;
      border: none;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
      font-family: "Roboto", sans-serif;
      color: white;
      background-color: #02a862;
      cursor: pointer;
      transition-duration: 0.3s;
      padding: 0;
    }

    .close-button {
      transform: rotate(45deg);
    }
  }

  h2 {
    margin: 14px;
    color: #4d4f5c;
    font-size: 22px;
    font-family: "Roboto", sans-serif;
  }

  .markers-lower {
    display: flex;
    margin: 12px;
  }

  .key {
    display: flex;

    .content-wrapper {
      width: 100%;

      .key-entry {
        display: flex;

        .key-text {
          margin-left: 10px;
          font-size: large;
        }
      }

      .key-content {
        display: flex;
        justify-content: space-between;
        margin: 20px;
        margin-right: 40px;

        &.middle {
          width: 81%;
        }

        &.last {
          width: 48%;
        }
      }

      .color-block {
        width: 20px;
        height: 20px;
        border-radius: 50%;

        &.eq {
          background-color: rgba(0, 255, 225, 0.7);
        }

        &.twenty-three {
          background-color: rgba(16, 107, 68, 0.7);
        }

        &.eg {
          background-color: rgba(36, 77, 182, 0.7);
        }

        &.cobra {
          background-color: rgba(0, 255, 0, 0.7);
        }

        &.mb {
          background-color: rgba(255, 0, 204, 0.7);
        }

        &.sch {
          background-color: rgba(24, 22, 123, 0.7);
        }

        &.kbev {
          background-color: rgba(202, 164, 11, 0.7);
        }

        &.bs {
          background-color: rgba(197, 33, 33, 0.7);
        }

        &.qw {
          background-color: rgba(247, 161, 237, 0.7);
        }

        &.cf {
          background-color: rgba(123, 179, 221, 0.7);
        }

        &.oco {
          background-color: rgba(255, 251, 0, 0.7);
        }

        &.treats {
          background-color: rgba(119, 197, 122, 0.7);
        }

        &.p2 {
          background-color: rgba(167, 208, 235, 0.7);
        }

        &.sbux {
          background-color: rgba(0, 255, 225, 0.7);
        }
      }
    }

    .hideKey {
      display: none;
    }
  }
}
