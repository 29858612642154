.asset-container {
  margin-right: 30px;
  margin-bottom: 30px;
  width: 150px;
  padding: 10px;
  border-radius: 15px;
  font-family: $font-family-medium;
  font-size: 16px;
  color: #707070;
  display: flex;
  justify-content: center;

  &.selected,
  &:hover {
    background-color: #e4e4e4;
  }

  button.asset-content {
    border: none;
    background: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .icon.icon-folder {
      display: block;
    }
  }

  .asset-icon-and-buttons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .asset-detail {
      flex-grow: 1;
      max-width: calc(100% - 120px);
    }

    .icon.icon-file {
      margin: 0 auto;
    }
  }

  .edit-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

  .asset-footer {
    display: flex;
    justify-content: center;
    align-items: flex-end;

    .asset-description {
      font-weight: bold;
      font-size: 14px;
      font-family: $font-family-book;
      color: $colour_grayscale_darkest;
      margin: 10px 0;
      padding: 0 3px;
      text-align: center;
      max-width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .asset-size {
      font-size: 14px;
      font-weight: normal;
      margin: 10px 0;
      padding: 0 3px;
    }
  }



  .document-icon-title {
    flex-grow: 1;
    max-width: calc(100% - 120px);
  }

  .asset-name {
    font-weight: bold;
    font-size: 14px;
    margin-top: 5px;
    font-family: $font-family-medium;
    color: $colour_grayscale_darkest;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
  }

  .button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 10px;

    button {
      margin-bottom: 5px;
    }
  }
}
