//This applies to both user and siteid process users pages.
.users-page {
  .user-container {
    display: flex;
    flex-direction: rows;
    flex-wrap: wrap;
  }

  .header-buttons {
    > div {
      margin-top: 10px;
      display: flex;
      justify-content: flex-end;

      button {
        margin-left: 10px;
      }
    }
  }

  .user-header {
    display: flex;
    justify-content: space-between;

    button {
      margin-left: 10px;
    }
  }

  .pagination-controls {
    font-size: 13px;
    font-family: $font-family-medium;
    color: #4d4f5c;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      background-color: #ebeaea;
      font-weight: bold;
      color: #c3c3c3;
      border: none;
      border-radius: 3px;
      height: 30px;
      width: 30px;
      margin-left: 5px;
      font-size: 20px;
      cursor: pointer;

      &:disabled {
        cursor: default;
        color: #f0f0f0;
      }
    }
  }

  .user-record {
    @media only screen and (max-width: 1980px) and (min-width: 1681px) {
      width: calc(25% - 64px);

      &:nth-child(4n) {
        margin-right: 0;
      }
    }

    @media only screen and (max-width: 1680px) and (min-width: 1121px) {
      width: calc(33% - 58px);

      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    @media only screen and (max-width: 1120px) and (min-width: 660px) {
      width: calc(50% - 43px);
      margin-right: 20px;

      &:nth-child(2n) {
        margin-right: 0;
      }
    }

    @media only screen and (max-width: 660px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
}
