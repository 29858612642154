.total {
    margin-top: 40px;

    input {
        width: 75px;
    }
}

.group-details {
    &.summary-progress {
        height: 130px;
    }
}
