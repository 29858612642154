.form-container {
  display: flex;
  flex-direction: column;
  background:
    transparent linear-gradient(180deg, #f4f5fa 0%, #eeecec 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.16);
  border-radius: 25px;
  padding-bottom: 10px;

  h4 {
    margin-left: 36px;
  }

  .form-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 36px;
    padding-top: 36px;
    margin-bottom: 12px;

    &.apac {
      padding-top: 0;
    }

    .header-title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    h2 {
      padding-block-start: 0;
      padding-block-end: 0;
      margin-left: 24px;
      margin-block-end: 0;
      color: #707070;
      font-size: 22px;
      font-family: $font-family-medium;
    }
  }

  .form-explanation {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 0 36px;

    p {
      font-family: $font-family-book;
      font-size: 15px;
    }
  }

  .form-body {
    margin: 0 36px;
    margin-bottom: 28px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-end;

    &.form-body-top {
      align-items: flex-start;
    }

    label {
      margin-right: 30px;

      @media only screen and (max-width: 1980px) and (min-width: 1921px) {
        width: calc(25% - 25px);

        &:nth-child(4n) {
          margin-right: 0;
        }
      }

      @media only screen and (max-width: 1920px) and (min-width: 1241px) {
        width: calc(33% - 21px);

        &:nth-child(3n) {
          margin-right: 0;
        }
      }

      @media only screen and (max-width: 1240px) and (min-width: 1121px) {
        width: calc(33% - 11px);
        margin-right: 20px;

        &:nth-child(3n) {
          margin-right: 0;
        }
      }

      @media only screen and (max-width: 1120px) and (min-width: 800px) {
        width: calc(50% - 22px);
        margin-right: 20px;

        &:nth-child(2n) {
          margin-right: 0;
        }
      }

      @media only screen and (max-width: 800px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
      }

      input,
      select,
      textarea {
        width: 100%;
        resize: vertical;
      }
    }

    & > div {
      margin-right: 40px;
    }

    .sub-form-container {
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;

      .sub-form {
        display: flex;
        border-radius: 15px;
        background-color: white;
        padding: 10px;
      }
    }
  }

  .form-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-top: 15px;
    padding-left: 36px;

    button {
      width: 330px;
      height: 50px;
      font-size: 20px;
      border-radius: 25px;
      margin-bottom: 20px;
      font-family: $font-family-medium;
      font-weight: normal;

      @media only screen and (max-width: 1980px) and (min-width: 1921px) {
        width: calc(25% - 36px);
      }

      @media only screen and (max-width: 1920px) and (min-width: 1241px) {
        width: calc(33% - 33px);
      }

      @media only screen and (max-width: 1240px) and (min-width: 1121px) {
        width: calc(33% - 20px);
      }

      @media only screen and (max-width: 1120px) and (min-width: 800px) {
        width: calc(50% - 36px);
      }

      @media only screen and (max-width: 800px) {
        width: calc(100% - 36px);
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }
}
