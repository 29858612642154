.dashboard {
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: normal;
  }

  h3 {
    font-size: 20px;
    margin-block-start: 1em;
    margin-left: 2em;
  }

  h4 {
    font-size: 18px;
  }

  .word-cloud {
    margin: 20px;
    width: auto;
  }

  .small-stat,
  .word-cloud {
    width: 94%;
    height: 75px;
    background-color: white;
    color: #707070;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    border-radius: 15px;
    margin-bottom: 10px;
    position: relative;
    overflow: hidden;

    &--overlay {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background-color: rgb(15, 130, 150);
      color: white;
      border-radius: 0;
    }

    .small-stat__name {
      font-size: 22px;
      white-space: nowrap;
    }

    .small-stat__values {
      .small-stat__value {
        font-size: 42px;
        margin: 0 12px;
      }
    }
  }

  .star-rating {
    position: relative;
    margin-bottom: 25px;

    .template {
      display: flex;
    }

    .score {
      display: flex;
      overflow: hidden;
      position: absolute;
      top: 0;
    }
  }

  .button-set {
    display: flex;
    flex-wrap: nowrap;

    button {
      border: none;
      background-color: white;
      box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.16);
      color: #707070;
      padding: 5px;
      font-size: 16px;
      margin-bottom: 10px;
      text-align: center;
      height: 38px;
      display: flex;
      align-items: center;
      width: 150px;
      justify-content: center;
      cursor: pointer;

      &:last-child {
        border-radius: 0 5px 5px 0;
        margin-right: 10px;
      }

      &:first-child {
        border-radius: 5px 0 0 5px;
      }

      &.active {
        background-color: $colour_good;
        color: white;
        box-shadow: none;
      }
    }
  }

  .stat-container {
    display: flex;
    flex-wrap: wrap;

    .stat-group {
      background:
        transparent linear-gradient(180deg, #f4f5fa 0%, #eeecec 100%)
        0% 0% no-repeat padding-box;
      box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.16);
      border-radius: 25px;
      margin-right: 10px;
      margin-bottom: 10px;
      flex-grow: 1;
      flex-shrink: 0;

      @media only screen and (max-width: 700px) {
        width: 100%;
      }

      .stat-body {
        min-width: 300px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        flex-direction: column;

        &.fixed-height {
          height: 450px;

          @media only screen and (max-width: 700px) {
            height: auto;
          }
        }

        &--x2 {
          min-width: 610px;
          width: 100%;

          @media only screen and (max-width: 700px) {
            min-width: auto;
          }

          .small-stat {
            width: 45%;

            @media only screen and (max-width: 700px) {
              width: 94%;
            }

            &--overlay {
              width: auto;
            }
          }

          &.word-cloud {
            margin: 20px;
            width: auto;
            min-height: 450px;
          }
        }
      }
    }
  }
}
