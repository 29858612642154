.my-documents-page.order-documents {
  .flex {
    display: flex;
    justify-content: space-between;

    .floating-panel {
      flex-grow: 1;
    }
  }

  .m-l-sm {
    margin-left: 10px;
  }

  .m-r-sm {
    margin-right: 10px;
  }

  .req-doc-toggles {
    flex-wrap: wrap;
    display: flex;
  }

  .required-doc-panel {
    display: block;
  }

  .upload-panel {
    display: block;
    min-width: 600px;
  }

  .max-width {
    width: 100%;
  }

  .zip-download-container {
    display: flex;

    .action {
      width: 200px;
    }
  }
}