.connectwrapper {
  width: 100vw;
}

.connect-page {
  display: flex;
  flex-direction: column;
  width: 100%;

  .horizontal-connect {
    display: flex;
    background-color: $colour_grayscale_white;
    position: relative;

    .connect-menu {
      flex-grow: 0;
      flex-shrink: 0;
    }

    .container {
      width: 100%;
      position: relative;
    }
  }

  .no-items {
    display: flex;
    justify-content: center;
    margin: 20px;
    min-height: 170px;
    color: rgb(37, 37, 37);
    padding: 40px;
    font-size: 20px;
  }
}
