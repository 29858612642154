.edit-email-page {
  h5 {
    margin-top: 0;
  }

  .edit-notifications {
    background-color: white;
    border-radius: 15px;
    margin-top: 20px;
    margin-bottom: 10px;
    transition: 0.3s;
    padding: 20px;
  }

  .label {
    &.apac {
      margin-top: -11px;
      margin-left: 20px;
    }
  }

  .settings,
  .email-settings {
    display: flex;
    flex-direction: row;
    background:
      transparent linear-gradient(
        180deg,
        #f4f5fa 0%,
        #eeecec 100%
      ) 0% 0% no-repeat padding-box;
    box-shadow: 1px 1px 6px rgb(0 0 0 / 16%);
    border-radius: 25px;
    padding: 36px;
    margin-bottom: 20px;
    transition-duration: 0.3s;
  }

  .email {
    display: flex;
    flex-direction: column;
    width: 330px;
    margin: 10px;
  }

  .email-header {
    display: flex;
    align-items: center;
    margin: 6px;
  }

  button.add {
    width: 200px;
    height: 35px;
    font-weight: normal;
    font-size: 16px;
    border-radius: 17px;
    border: 1px solid #bbb;
    font-family: "Roboto", sans-serif;
    transition-duration: 0.3s;
    color: white;
    background-color: #02a862;
    box-shadow: 0 3px 6px #00000029;
    cursor: pointer;
    margin-left: 5px;
  }

  .wrapper,
  .wrapper-top {
    line-height: unset;
    color: #4d4f5c;
    margin-top: 4px;
    margin-bottom: auto;
    width: 330px;
    height: 30px;
    display: flex;
    align-items: center;

    &.apac {
      margin-top: 20px;
    }

    button.action {
      width: 60px;
    }
  }

  .wrapper-top {
    margin-left: 0;
    margin-bottom: 10px;
  }

  .label {
    margin: 6px;
    margin-top: 4px;
    min-width: 132px;
  }

  .item-list {
    .toggle-wrapper,
    .toggle-wrapper-top {
      width: 44px;
      height: 20px;
      border: none;
      border-radius: 30px;
      position: relative;
      flex-shrink: 0;
      margin: 4px;

      .toggle-wrapper-top {
        margin-left: 132px;
      }

      .toggle-icon {
        position: absolute;
        cursor: pointer;
        top: -3px;
        left: 0;
        right: -3px;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 34px;

        &::before {
          position: absolute;
          content: "";
          height: 16px;
          width: 16px;
          left: 4px;
          bottom: 4px;
          background-color: white;
          -webkit-transition: 0.4s;
          transition: 0.4s;
          border-radius: 50%;
        }
      }

      input:checked + .toggle-icon {
        background-color: #02a862;
      }

      input:focus + .toggle-icon {
        box-shadow: 0 0 1px #02a862;
      }

      input:checked + .toggle-icon::before {
        -webkit-transform: translateX(24px);
        -ms-transform: translateX(24px);
        transform: translateX(24px);
      }

      input:disabled + .toggle-icon {
        opacity: 0.5;
      }

      input[type="checkbox"] {
        position: fixed;
        top: -50px;
        left: -100px;
        opacity: 0;
      }
    }
  }
}

.new-email {
  display: flex;
  margin-top: 20px;

  button {
    margin-left: 20px;
    width: 35px;
  }
}
