.booking-form {
  display: flex;
  flex-wrap: wrap;
  background-color: $colour-grayscale-white;
  border-radius: 25px;
  margin-bottom: 20px;
  padding: 18px 24px;

  .label-value-pair {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px;
    flex-wrap: wrap;

    label {
      margin-right: 10px;
      line-height: normal;
      min-width: 250px;
      color: #5d5a5a;
    }

    span {
      overflow-wrap: break-word;
      word-break: break-word;
      font-weight: normal;
      color: #7f7f7f;
    }

    div {
      overflow-wrap: break-word;
      word-break: break-word;
      color: #7f7f7f;
    }
  }

  .booking-form-header {
    font-size: 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    color: #7f7f7f;
    flex-grow: 1;
    font-family: $font-family-book;

    & > div {
      flex-grow: 0;
    }

    label {
      font-size: 24px;
      font-family: $font-family-medium;
      min-width: 248px;
    }
  }

  .booking-form-body {
    display: flex;
    flex-wrap: wrap;

    & > div {
      flex-grow: 1;
    }
  }
}
