.kpi-configurations {
  width: 100%;

  .config-item {
    padding: 0 15px;

    .item {
      display: flex;
      align-items: center;
      padding: 0 30px;

      .name {
        width: 150px;
      }

      .helper {
        margin: 0 2rem;
      }

      .value {
        display: flex;
        align-items: center;

        input {
          text-align: center;
          margin: 0;
        }

        .form-control {
          width: 100px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
