.home-page {
  h2 {
    color: #4d4f5c;
    font-size: 24px;
    font-family: $font-family-medium;
    margin-block-end: 20px;
    margin-block-start: 0;
  }

  .menuContainer {
    display: flex;
    flex-wrap: wrap;
  }

  .floating-panel {
    display: flex;
    flex-direction: column;
    background:
      transparent linear-gradient(180deg, #f4f5fa 0%, #eeecec 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.16);
    border-radius: 25px;
    padding: 36px;
    padding-bottom: 0;
    opacity: 0;
    transition-duration: 0.3s;

    @media only screen and (max-width: 735px) {
      padding-right: 11px;
      padding-left: 11px;
    }

    &.loaded {
      opacity: 1;
    }

    .floating-header {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;

      h2 {
        margin-block-end: 0;
        margin-block-start: 0;
        color: #6e6e82;
        font-size: 22px;
        font-family: $font-family-medium;
      }

      p {
        margin-block-start: 10px;
        margin-block-end: 0;
        color: #6e6e82;
        font-size: 15px;
        font-family: $font-family-book;
      }
    }

    .menu-container {
      display: flex;
      flex-wrap: wrap;

      .tile {
        width: 290px;
        height: 103px;
        background: none;
        background-color: white;
        box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.16);
        border-radius: 15px;
        margin-right: 25px;
        margin-bottom: 39px;
        display: flex;
        cursor: pointer;
        align-items: center;

        &.disabled {
          background:
            transparent
            linear-gradient(
              180deg,
              rgba(245, 246, 250, 0.75) 0%,
              rgba(245, 245, 245, 0.75) 100%
            )
            0% 0% no-repeat padding-box;
          box-shadow: 0 0 3px rgba(0, 0, 0, 0.16);
          cursor: default;
        }

        @media only screen and (max-width: 1980px) and (min-width: 1921px) {
          width: calc(25% - 25px);

          &:nth-child(4n) {
            margin-right: 0;
          }
        }

        @media only screen and (max-width: 1920px) and (min-width: 1533px) {
          width: calc(33% - 21px);

          &:nth-child(3n) {
            margin-right: 0;
          }
        }

        @media only screen and (max-width: 1532px) and (min-width: 1241px) {
          width: calc(33% - 21px);

          &:nth-child(3n) {
            margin-right: 0;
          }
        }

        @media only screen and (max-width: 1240px) and (min-width: 1051px) {
          width: calc(33% - 18px);
          margin-right: 20px;

          &:nth-child(3n) {
            margin-right: 0;
          }
        }

        @media only screen and (max-width: 1050px) and (min-width: 736px) {
          width: calc(50% - 22px);
          margin-right: 20px;

          &:nth-child(2n) {
            margin-right: 0;
          }
        }

        @media only screen and (max-width: 735px) {
          width: calc(100%);
          margin-right: 0;
          margin-bottom: 20px;
        }

        .tile-text {
          display: flex;
          flex-direction: column;
          justify-content: center;
          flex-wrap: wrap;
          align-self: auto;
          margin-right: 10px;
          flex-grow: 1;

          .tile-name {
            text-align: left;
          }

          &.file-text {
            display: block;
          }
        }
      }
    }
  }
}
