.table {
  .table-header,
  .table-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .pagination,
    input.search {
      margin-bottom: 15px;
      margin-top: 15px;
    }

    .search-container {
      position: relative;

      &::before {
        content: url("/assets/icons/Search 20.svg");
        background-position: center;
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        top: 20px;
        bottom: 0;
        left: 10px;
        position: absolute;
      }
    }

    input.search {
      height: 30px;
      width: 100%;
      padding-left: 40px;
      color: white;
      background-color: $colour_grayscale_darkest;
      border: none;

      &::placeholder {
        color: white;
      }
    }

    h2 {
      margin-block-end: 20px;
      margin-block-start: 20px;
      color: #4d4f5c;
      font-size: 22px;
      font-family: "Roboto", sans-serif;

      @media only screen and (max-width: 600px) {
        display: none;
      }
    }
  }

  .table-scroll {
    width: 100%;
    overflow: auto;
  }

  .extended-details{
    display:flex;
  }

  table {
    thead tr th {
      height: 20px;
      
      &.sticky-column{
            position: sticky;
            left: 0;
            width: 250px;
            min-width: 250px;
            z-index: 2;
            &:nth-child(5) {
                left: 250px;
              }
      }
      

      &:first-child {
        width: 25px;
      }
    }

    tbody tr {
      &.active {
        background-color: #4bd991;
      }

      &:nth-child(even) {
        td,th{
        background: #ebeaea;
        }

      }

      &:nth-child(odd) {td,
        th {
        background: #f7f7f7;
        }
      }


      th.sticky-column{
        text-align: left;
        font-weight:normal;
        position: sticky;
        width:250px;
        min-width: 250px;
        left: 0;
        z-index: 2;

        &:first-child {
            width: 25px;
          }
        
          &:nth-child(3) {
            width: 25px;
          }
        
          &:nth-child(2) {
            width: 25px;
          }

          &:nth-child(5) {
            left: 250px
          }

          &:last-child {
            width: 60px;
          }
      }

      td {

        &.red{
          //background-color: #d3372a;
          select {
              background-color: #d3372a;
              color:white;
            }
          
            input {
              background-color: #d3372a;
              color: white;
            }
        }

        &.yellow {
          //background-color:#ffcd47;
          select{
            background-color: #ffcd47;
          }
          input{
            background-color: #ffcd47;
          }
        }

        &:first-child {
          width: 25px;
        }

        &:nth-child(3) {
          width: 25px;
        }

        &:nth-child(2) {
          width: 25px;
        }

        &:last-child {
          width: 60px;
        }

        input{
          width:120px;
        }

        label.inline-checkbox{
          width: 50px;
        }

        select{
          margin-bottom:0;
          width:150px;
        }

        .site-name-link {
          cursor: pointer;
        }
      }
    }

    button {
      width: 112px;
      height: 28px;
      margin: 6px;
      border-radius: 30px;
      font-size: 16px;
      border: none;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
      font-family: $font-family-medium;
      color: white;
      background-color: $colour_SBgreen_light;
      cursor: pointer;
      transition-duration: 0.3s;

      &:hover,
      &:active,
      &:focus {
        transition-duration: 0.3s;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
      }

      &.bad {
        background-color: #d3372a;
      }

      &.multi-select {
        height: 24px;
        width: 24px;
        border-radius: 8px;
        background-color: $colour_SBgreen_dark;
      }
    }
  }
}
