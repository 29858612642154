.page.default-responses {
  .default-responses-list-header {
    .header-buttons {
      .search {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        input {
          margin-right: 8px;
        }
      }
    }
  }
}
