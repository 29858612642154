table {
  border-spacing: 0;
  width: 100%;

  thead {
    tr {
      th {
        background-color: #4d4f5c;
        color: white;
        height: 49px;
        font-size: 16px;
        font-family: $font-family-book;
        font-weight: normal;
        padding: 10px;
        text-align: left;

        &:first-child {
          border-radius: 10px 0 0 10px;
        }

        &:last-child {
          border-radius: 0 10px 10px 0;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        font-size: 16px;
        font-family: $font-family-book;
        padding-left: 10px;

        input {
          width: 100%;
          padding: 0;
          margin: 0;
        }

        &.right-cell {
          text-align: right;
        }

        &.left-cell {
          text-align: left;
        }
      }

      &:nth-child(even) {
        background: #ebeaea;
      }

      &:nth-child(odd) {
        background: #f7f7f7;
      }
    }
  }

  &.table-display-mode-1 {
    tbody {
      tr {
        td {
          padding-top: 30px;
          padding-bottom: 30px;
        }
      }
    }
  }

  &.table-display-mode-2 {
    tbody {
      tr {
        td {
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }
    }
  }

  &.table-display-mode-3 {
    tbody {
      tr {
        td {
          padding-top: 4px;
          padding-bottom: 4px;
        }
      }
    }
  }

  &.table-display-mode-4 {
    tbody {
      tr {
        td {
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
    }
  }

  &.table-display-mode-5 {
    tbody {
      tr {
        td {
          padding-top: 15px;
          padding-bottom: 15px;
        }
      }
    }
  }
}
