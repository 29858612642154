@import '../../../style/variables';

.create-order-page {
  .floating-header {
    margin-bottom: 0;
  }

  .search-container {
    display: flex;
    flex-wrap: wrap;

    label {
      margin-right: 10px;
    }

    .action {
      width: 100px;
      align-self: flex-end;
      margin-bottom: 20px;
    }
  }

  .accordion {
    .accordion-header {
      cursor: default;
    }
  }

  .create-order-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .group-details {
      width: calc(50% - 10px);

      @media screen and (max-width: 991px) {
        width: 100%;
      }
    }
  }

  .horizontal-flex.progress-stages {
    flex-wrap: wrap;

    .progress-stage {
      flex-basis: 230px;

      label,
      input {
        width: 100%;
      }
    }
  }

  .submitted {
    :invalid,
    :invalid:focus {
      border: 2px solid $colour_bad;
      box-shadow: none;
    }
  }

  .button-container {
    display: flex;

    :first-child {
      margin-right: 10px;
    }
  }

  .submit-result {
    line-height: 5px;
    margin-left: 15px;
    font-weight: 300;
  }

  .progress-state-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .progress-state {
    .progress-update {
      padding: 8px 5px;
    }
  }

  .comments-container,
  .comments {
    width: 100%;

    textarea {
      width: 100%;
      display: block;
    }
  }

  .modal-blocker .modal {
    width: 75vw;
    height: 80vh;
    border-radius: 10px;
    background-color: $colour_grayscale_white;

    .horizontal-flex {
      > div:first-child {
        margin-left: 0;
      }

      input {
        background-color: $colour_grayscale_light;
      }
    }

    .modal-body {
      margin-top: 0;
    }

    .modal-content {
      height: 100%;
      padding-bottom: 5px;
      overflow-y: auto;
    }
  }
}
