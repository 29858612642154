.email-document-management {
  .filename-entry {
    width: 300px;
    max-width: 300px;
  }

  .email-entry {
    width: 405px;
    max-width: 405px;
  }

  .document-entry {
    width: 315px;
    max-width: 315px;
  }
}
