.template.template-7 {
  color: $colour_grayscale_black;
  display: flex;
  justify-content: center;

  .template-row {
    flex-direction: column;
    max-width: 600px;
    box-shadow: 0 2px 8px 1px #3c3c3c87;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .heading-row {
    display: flex;
    justify-content: space-between;
    padding: 48px;
    padding-top: 24px;
    padding-bottom: 24px;
    width: 100%;
  }

  .content-label {
    margin: unset;
  }

  .content-container {
    padding: 48px;
    padding-top: 24px;
  }

  .image {
    max-height: 400px;

    img {
      align-self: center;
      border-radius: unset;
      margin-bottom: 10px;
    }
  }

  .col-1,
  .col-3 {
    flex-grow: 0;
    flex-shrink: 0;
    padding: unset;
    margin: unset;
  }

  .col-1.image {
    max-width: unset;
    align-self: center;
  }

  .col-2 {
    flex-grow: 1;
    flex-shrink: 1;
    margin: 48px;
  }

  h2.heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $colour_grayscale_black;
    text-align: center;
    font-size: 50px;
    line-height: 60px;
    font-weight: 400;
    text-transform: uppercase;
    margin-left: 48px;
  }

  h3.sub-heading {
    color: $colour_grayscale_black;
    font-weight: 400;
    margin: 0;
  }

  .content {
    color: $colour_grayscale_black;
    transition-duration: 0.3s;
    max-height: 550px;
    overflow: auto;

    .content-content {
      height: 0;
      overflow: hidden;
    }

    &.content-expanded {
      max-height: 550px;
      overflow: auto;

      .content-content {
        height: 100%;
        overflow: auto;
      }
    }

    p {
      color: unset;
    }

    a {
      padding: 0;
      color: unset;
      text-decoration: underline;
      font-weight: 500;
    }
  }

  .content-expander {
    margin-top: 15px;
  }
}
