.login-page {
  background: white;
  display: flex;

  @media (max-width: 940px) {
    flex-direction: column;
    overflow: visible;
    align-items: center;
    position: relative;
    border-bottom: 0;
  }

  .orderly-footer {
    font-family: $font-family-medium;
    color: $colour_grayscale_dark;
    font-size: 13px;
    display: none;

    /* position: absolute; */
    bottom: 0;
    left: 0;
    align-items: center;
    margin-bottom: 10px;
    margin-left: 40px;
    cursor: pointer;

    @media (max-width: 940px) {
      display: flex;
      background-color: white;
      width: 100%;
      margin-bottom: 0;
      height: 100px;
    }

    .orderly-logo {
      height: 16px;
      margin-left: 4px;
    }
  }

  .orderly-logo-footer {
    font-family: $font-family-medium;
    color: $colour_grayscale_dark;
    font-size: 13px;
    align-items: flex-start;
    bottom: 0;
    left: 0;
    display: flex;
    margin-bottom: 10px;
    cursor: pointer;

    @media (max-width: 940px) {
      background-color: white;
      margin-bottom: 0;
      margin-left: 0;
      padding-left: 0;
    }

    .orderly-logo {
      height: 16px;
      margin-left: 4px;
    }
  }

  .login-picture {
    background-color: #00643c;
    background-image: url("/assets/Create-Opportunities-BG.jpg");
    width: calc(50vw);
    background-size: cover;
    background-repeat: no-repeat;
    border-left: 2px solid;
    border-color: $colour_SBgreen_dark;
    border-bottom: 5px solid;
    border-color: $colour_SBgreen_dark;
    min-height: calc(100vh);

    @media (max-width: 940px) {
      width: 100vw;
      height: auto;
      min-height: 100vh;
      border: none;
    }
  }

  .login-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-bottom: 5px solid;
    border-color: $colour_SBgreen_dark;
    width: 50vw;
    min-height: calc(100vh);

    @media (max-width: 940px) {
      width: 100vw;
    }

    footer {
      display: flex;
      flex-direction: row;
      font-family: $font-family-book;
      font-size: 15px;
      align-self: stretch;
      margin-bottom: 10px;
      margin-right: 30px;
      margin-left: 30px;
      align-items: flex-end;
      justify-content: space-between;

      .link {
        color: $colour_SBgreen_light;
        margin-bottom: 10px;
        text-align: right;
      }
    }

    .footer-links {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }

  .password-validation-message {
    color: red;
    font-size: small;
    margin: 10px 0 15px 0;
  }

  .login-form {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    width: 454px;
    align-items: center;

    @media (max-width: 940px) {
      width: 100vw;
      width: auto;
      border: none;
    }

    label {
      font-size: 15px;
      font-family: $font-family-light;
      align-self: left;

      &.wrapping {
        display: flex;
        align-items: center;

        span {
          margin-left: 10px;
        }
      }

      .toggle-wrapper {
        width: 40px;
        height: 25px;
        border: none;
        border-radius: 25px;
        margin-bottom: 0;
        position: relative;
        flex-shrink: 0;

        input:checked + .toggle-icon::before {
          transform: translateX(15px);
        }

        .toggle-icon::before {
          position: absolute;
          content: "";
          height: 21px;
          width: 21px;
          left: 2px;
          bottom: 2px;
          background-color: white;
          transition: 0.4s;
          border-radius: 50%;
        }
      }
    }

    .logo {
      background-image: url("/assets/clear-logo.png");
      height: 99px;
      width: 99px;
      background-repeat: no-repeat;
      background-size: cover;
      margin-top: 20px;
      flex-shrink: 0;
      align-self: center;
      cursor: pointer;
      margin-bottom: 37px;
    }

    h2 {
      color: $colour_grayscale_darkest;
      font-size: 24px;
      text-align: center;
      font-family: $font-family-medium;
    }

    input.float,
    select.float {
      border: 2px solid $colour_grayscale_light;
      width: 350px;
      font-family: $font-family-book;
      font-size: 15px;
      font-weight: normal;

      &:focus {
        outline: none;
        border: 2px solid #02a862;
      }

      &.invalid {
        border: 2px solid #ed4646;
      }
    }

    form {
      display: flex;
      flex-direction: column;
      margin-top: 40px;
      align-items: center;

      .submission {
        display: flex;
        flex-direction: column;
        align-items: center;

        button.link {
          text-align: left;
          margin-top: 30px;
          padding: 0;
        }

        button.float {
          align-self: center;
          margin-top: 30px;

          &.logout {
            margin-left: 15px;
          }

          &.negative {
            background-color: #ed4646;
            margin-bottom: 15px;
          }

          &:disabled {
            opacity: 0.5;
            box-shadow: none;
            cursor: default;
          }
        }
      }
    }
  }
}

.orderly-footer {
  font-family: $font-family-medium;
  color: $colour_grayscale_dark;
  font-size: 16px;
  display: flex;
  justify-content: flex-start;
  margin-left: 30px;
  max-width: 1100px;
  margin: 0 auto;
  cursor: pointer;

  .orderly-logo {
    height: 20px;
    margin-left: 4px;
  }
}

.full-code-wrapper {
  display: flex;
  align-items: baseline;

  input {
    margin-left: 5px;
  }
}
