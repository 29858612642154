.mape-table-container {
  margin-top: 5px !important;
  margin-bottom: 5% !important;
  padding: 0 50px;
}

.carousel-container {
  margin-bottom: 15px !important;
}

.filter-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.carousel-chart-container {
  display: flex;
  flex-direction: column;
}

.mape-filter-container {
  width: 100%;
  margin-left: 3%;
}

.mape-filter-width {
  width: 50% !important;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  background: #000000ad !important;
  margin-top: 5% !important;
}

.carousel .control-dots .dot {
  background: #0a0a0a !important;
  size: 100px;
}

.mape-table-container {
  overflow: auto !important;
}

.mape-table-container .table-style {
  width: 100% !important;
  table-layout: auto !important;
}

.mape-table-container .table-style td {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.category-filter-container {
  width: 80%;
  display: flex;
  margin-bottom: 20px;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-between;
  margin-left: 5%;
}

.topTen-filter-container {
  width: 80%;
  display: flex;
  margin-bottom: 20px;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-between;
  margin-left: 5%;
}
