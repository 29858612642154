.connect-sidebar {
  min-height: calc(100vh - 125px);
  background-color: white;
  margin-left: -60px;
  margin-right: 60px;
  z-index: 4;
  box-shadow: 0 0 7px 0 #3c3c3c87;
  transition-duration: 0.3s;
  position: absolute;
  padding-top: 20px;
  width: 0;
  top: 0;
  bottom: 0;
  overflow: auto;

  &.dark {
    background-color: #17211d;

    .close-button {
      color: white;
    }
  }

  &.show {
    width: 700px;
    margin-right: 0;
    padding-left: 300px;

    @media only screen and (max-width: 1200px) {
      padding-left: 0;
      margin-left: 0;
      width: 400px;
    }

    @media only screen and (max-width: 800px) {
      width: 100%;
    }
  }

  .connect-sidebar-header {
    display: flex;
    justify-content: flex-end;

    .close-button {
      font-size: 22px;
      background: none;
      border: none;
      cursor: pointer;
      margin-right: 10px;
    }
  }

  .connect-sidebar-content {
    margin-right: 15px;
    height: calc(100% - 80px);
    overflow: auto;

    .modal-blocker {
      .modal {
        max-width: 60%;
      }
    }

    @media only screen and (max-width: 1200px) {
      margin-left: 15px;

      .modal-blocker {
        .modal {
          max-width: 95%;
        }
      }
    }

    .hide {
      display: none;
    }

    .notifications {
      color: #707070;
      text-align: center;
    }

    .weather,
    .events {
      height: 100%;
    }

    .live-chat {
      .floating-panel {
        background: none;
        box-shadow: none;
        color: white;
        padding: 0;

        .floating-header {
          margin-right: 0;
          justify-content: flex-end;
          text-align: center;

          h2,
          p {
            color: white;
            margin-right: 0;
          }

          a {
            padding: 0;
            color: white;
            text-decoration: underline;
          }

          .icon {
            display: none;
          }
        }

        .message-text-container {
          color: black;
        }

        .conversation-controls {
          flex-direction: column;
          align-items: stretch;

          button.float {
            width: 100%;
            margin-left: 0;
          }
        }
      }

      .chat-room-container {
        .tile {
          margin-right: 0;
          margin: 5px;
          width: 100%;

          .tile-text {
            align-self: unset;
          }
        }
      }
    }
  }
}
