@import "variables.scss";

//Basic Components
@import "common/standard-html-elements.scss";
@import "common/standard-form-elements.scss";
@import "common/standard-button-elements.scss";
@import "common/standard-table-elements.scss";
@import "common/standard-icon-elements.scss";

//Standard Layouts

@import "common/standard-layout.scss";
@import "common/standard-form-layout.scss";
@import "common/standard-table-layout.scss";
@import "common/standard-dashboard-layout.scss";

//Standard SiteID

@import "common/siteid-icon-elements.scss";

/* potentially legacy component */
div.panel {
  background-color: white;
  padding: 20px 20px;
  margin: 15px 0;
}

/* hack during style migration */
.header-retro {
  display: flex;
  justify-content: space-between;
}

/* hack to fix non-google stye map behaviour */
.gm-style-mtc > :nth-child(2) {
  background-color: pink !important;
  display: none;
}
