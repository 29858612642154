.connect-menu-tab {
  background-color: $colour_SBgreen_dark;
  border: none;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  width: 40px;
  height: 40px;
  position: absolute;
  padding: 6px;
  top: 20px;
  left: 0;
  box-shadow: 0 0 5px 3px #3c3c3c87;
}

.connect-menu {
  min-height: calc(100vh - 125px);
  background-color: $colour_SBgreen_dark;
  width: 200px;
  z-index: 8;
  box-shadow: 0 0 5px 3px #3c3c3c87;
  border-radius: 0 75px 0 0;
  transition-duration: 0.3s;
  padding-top: 20px;

  .connect-menu-hide-button {
    display: none;
  }

  @media only screen and (max-width: 1200px) {
    position: absolute;
    top: 0;
    bottom: 0;

    .menu-menu {
      height: 40px;
    }

    .connect-menu-hide-button {
      background-color: $colour_SBgreen_dark;
      border: none;
      display: block;
      width: 40px;
      height: 40px;
      position: absolute;
      transform: rotate(180deg);
      padding: 6px;
      top: 20px;
      left: 0;
    }

    &.hide {
      width: 0;
      overflow-x: hidden;
    }
  }

  .top-content,
  .middle-content,
  .bottom-content {
    color: white;
    margin-right: -30px;
    margin-top: 26px;

    .label {
      margin-bottom: 10px;
      padding: 10px 30px;
    }

    .notifications,
    .tasks,
    .weather,
    .events,
    .live-chat {
      height: 70px;
      width: 100%;
      background-color: $colour_grayscale_darkest;
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 10px;
      padding-left: 30px;
      align-items: center;
      margin-right: -50px;
      margin-bottom: 10px;
      font-weight: normal;
      box-shadow: 0 2px 8px 1px #3c3c3c87;
      border: none;
      color: white;
      cursor: pointer;

      .number {
        font-size: 56px;
      }

      &.notifications {
        &.has-unread {
          background-color: $colour_status_3;
        }
      }

      &.tasks {
        &.has-unread {
          background-color: #eb5454;
        }
      }
    }

    .weather,
    .events {
      height: 40px;

      .number {
        font-size: 24px;
      }
    }

    .weather-detail {
      display: flex;
      align-items: center;
    }

    .weather {
      background-color: #95d7fb;
      color: black;

      img {
        height: 50px;
        margin-right: -10px;
      }
    }

    .events {
      background-color: #6137df;

      .icon.icon-qa-light {
        height: 28px;
        width: 28px;
        margin: 0;
        margin-bottom: 3px;
      }
    }

    .live-chat {
      background-color: #02a862;
    }
  }
}
