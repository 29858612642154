.comments {
  color: #4d4f5c;

  .indent {
    margin-left: 20px;
  }

  &__controls {
    margin-bottom: 36px;

    button {
      margin-right: 15px;
    }
  }

  &__line {
    &__controls {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #4d4f5c;
      font-weight: bold;
      margin-top: 10px;
      padding-bottom: 10px;

      &__left,
      &__right {
        display: flex;
        align-items: center;
      }

      &__badge {
        font-weight: normal;
        background-color: chocolate;
        font-size: 0.8em;
        color: white;
        padding: 0.2em;
        border-radius: 3px;
        margin-left: 7px;
      }

      &__buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;

        button {
          /* box-shadow: 0px 2px 6px #00000029; */
          background-color: #bbb;
          color: #626472;
          font-weight: bold;
          border: none;
          border-radius: 4px;
          height: 30px;
          width: 30px;
          margin-left: 5px;
          font-size: 20px;
          cursor: pointer;
          font-family: "Roboto", sans-serif;

          &:disabled {
            cursor: default;
            background-color: #ebeaea;
            box-shadow: none;
          }
        }
      }
    }

    &__content {
      margin-top: 20px;

      .file-holder {
        display: flex;

        @media only screen and (max-width: 750px) {
          flex-wrap: wrap-reverse;
        }

        .file-container {
          margin-bottom: 0;
        }
      }
    }

    &__footer {
      text-align: right;
      font-size: 0.8em;
      margin-bottom: 30px;
    }
  }
}

.extension__line {
  margin: 30px 20px;
  padding-left: 10px;
  border-left: 1px solid #737375;

  &__controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #737375;
    font-weight: normal;
    margin-top: 10px;
    padding-bottom: 10px;

    &__left,
    &__right {
      display: flex;
      align-items: center;
    }

    &__badge {
      font-weight: normal;
      background-color: chocolate;
      font-size: 0.8em;
      color: white;
      padding: 0.2em;
      border-radius: 3px;
      margin-left: 7px;
    }

    &__buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;

      button {
        /* box-shadow: 0px 2px 6px #00000029; */
        background-color: #bbb;
        color: #626472;
        font-weight: bold;
        border: none;
        border-radius: 4px;
        height: 30px;
        width: 30px;
        margin-left: 5px;
        font-size: 20px;
        cursor: pointer;
        font-family: "Roboto", sans-serif;

        &:disabled {
          cursor: default;
          background-color: #ebeaea;
          box-shadow: none;
        }
      }
    }
  }

  &__content {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  &__footer {
    text-align: right;
    font-size: 0.8em;
    margin-bottom: 30px;
  }
}

.flags {
  padding: 0 36px;
  padding-bottom: 15px;

  &__item {
    display: flex;
    margin-top: 15px;
  }
}

.status-chevrons {
  display: flex;
  align-items: center;

  @media only screen and (max-width: 750px) {
    flex-wrap: wrap-reverse;
  }

  .chevrons {
    display: flex;

    .chevron {
      width: 150px;
      height: 80px;
      position: relative;
      color: white;
      text-align: center;
      line-height: 80px;
      margin-right: 20px;

      &:last-child {
        border-radius: 0 25px 25px 0;
        width: 170px;
        padding-left: 10px;

        &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 0;
          height: 0;
          border-left: 30px solid #eeecec;
          border-top: 40px solid transparent;
          border-bottom: 40px solid transparent;
        }
      }

      &:first-child {
        border-radius: 25px 0 0 25px;

        &::before {
          content: "";
          position: absolute;
          right: -30px;
          top: 0;
          width: 0;
          height: 0;
          border-top: 40px solid transparent;
          border-bottom: 40px solid transparent;
          z-index: 1;
        }
      }

      &.status-1 {
        background-color: $colour_status_1;
        color: black;

        &:first-child {
          &::before {
            border-left: 30px solid $colour_status_1;
          }
        }
      }

      &.status-2 {
        background-color: $colour_status_2;
        color: black;

        &:first-child {
          &::before {
            border-left: 30px solid $colour_status_2;
          }
        }
      }

      &.status-3 {
        background-color: $colour_status_3;

        &:first-child {
          &::before {
            border-left: 30px solid $colour_status_3;
          }
        }
      }

      &.status-4 {
        background-color: $colour_status_4;

        &:first-child {
          &::before {
            border-left: 30px solid $colour_status_4;
          }
        }
      }

      &.status-5 {
        background-color: $colour_status_5;

        &:first-child {
          &::before {
            border-left: 30px solid $colour_status_5;
          }
        }
      }

      &.status-6 {
        background-color: $colour_status_6;

        &:first-child {
          &::before {
            border-left: 30px solid $colour_status_6;
          }
        }
      }

      &.status-7 {
        background-color: $colour_status_7;

        &:first-child {
          &::before {
            border-left: 30px solid $colour_status_7;
          }
        }
      }

      &.status-8 {
        background-color: $colour_status_8;

        &:first-child {
          &::before {
            border-left: 30px solid $colour_status_8;
          }
        }
      }

      &.status-9 {
        background-color: $colour_status_9;

        &:first-child {
          &::before {
            border-left: 30px solid $colour_status_9;
          }
        }
      }

      &.status-10 {
        background-color: $colour_status_10;

        &:first-child {
          &::before {
            border-left: 30px solid $colour_status_10;
          }
        }
      }

      &.status-11 {
        background-color: $colour_status_11;

        &:first-child {
          &::before {
            border-left: 30px solid $colour_status_11;
          }
        }
      }

      &.status-12 {
        background-color: $colour_status_12;

        &:first-child {
          &::before {
            border-left: 30px solid $colour_status_12;
          }
        }
      }
    }
  }
}

.status-1 {
  background-color: $colour_status_1;
}

.status-2 {
  background-color: $colour_status_2;
}

.status-3 {
  background-color: $colour_status_3;
}

.status-4 {
  background-color: $colour_status_4;
}

.status-5 {
  background-color: $colour_status_5;
}

.status-6 {
  background-color: $colour_status_6;
}

.status-7 {
  background-color: $colour_status_7;
}

.status-8 {
  background-color: $colour_status_8;
}

.status-9 {
  background-color: $colour_status_9;
}

.status-10 {
  background-color: $colour_status_10;
}

.status-11 {
  background-color: $colour_status_11;
}

.status-12 {
  background-color: $colour_status_12;
}
