.page.pos-catalogue-details {
  img {
    max-width: 330px;
    max-height: 200px;
    margin-right: 200px;
  }

  select {
    width: 100%;
  }

  .file-upload-wrapper {
    width: 330px;
  }

  .item-detail-image {
    display: flex;
    align-items: flex-end;
  }
}
