.site-id-application-page {
  .error {
    text-align: center;
    margin-top: 100px;
  }

  .clear-filter {
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .pagination.application {
    margin-top: 30px;
    width: 375px;
    font-size: 16px;
    justify-content: center;

    button {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  #siteidheadercontrols {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;

    & > label {
      margin-right: 20px;
      width: 445px;

      input {
        width: 445px;
      }
    }

    #siteidsubheadercontrols {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  .option-text {
    margin-right: 22px;
  }

  .top-right-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .licensee-view-wrapper {
      display: flex;
      align-items: center;

      .checkboxes {
        display: inline-block;
      }

      .slider.round {
        border-radius: 34px;
      }

      .slider.round::before {
        border-radius: 50%;
      }

      .switch {
        position: relative;
        display: inline-block;
        width: 50px;
        height: 25px;
      }

      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }

      .slider::before {
        position: absolute;
        content: "";
        height: 17px;
        width: 15px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }

      input:checked + .slider {
        background-color: #02a862;
      }

      input:focus + .slider {
        box-shadow: 0 0 1px #2196f3;
      }

      input:checked + .slider::before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
      }

      .option-text {
        font-size: 1em;
        margin-left: 30px;
        color: grey;
      }
    }

    .jump-control {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: 8px;
      margin-top: 20px;
      margin-bottom: 20px;
      position: relative;

      .sref-prefix {
        position: absolute;
        right: 170px;
      }

      input {
        width: 145px;
        margin: 8px;
        padding-left: 65px;
      }

      .search-button {
        width: 60px;
        height: 32px;
        margin: 8px;
        border-radius: 30px;
        font-size: 16px;
        border: none;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
        font-family: "Roboto", sans-serif;
        color: white;
        background-color: #02a862;
        cursor: pointer;
        transition-duration: 0.3s;

        &:disabled {
          opacity: 0.5;
          box-shadow: none;
          cursor: default;
        }
      }
    }
  }

  .detail-splitter {
    display: flex;
    justify-content: space-between;

    .detail-stacker {
      display: flex;
      flex-direction: column;
      width: calc(50% - 10px);

      @media only screen and (max-width: 950px) {
        width: 100%;
      }

      flex-grow: 0;
    }

    .fields {
      width: calc(50% - 10px);

      @media only screen and (max-width: 950px) {
        width: 100%;
      }

      flex-grow: 0;
    }

    @media only screen and (max-width: 950px) {
      flex-direction: column;
    }
  }

  .edit-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .bulkedit {
      position: relative;

      &::after {
        width: 12px;
        height: 8px;
        position: absolute;
        content: url(/assets/icons/DownArrow.svg);
        color: white;
        background-repeat: no-repeat;
        right: 15px;
      }
    }

    .bulkedit-menu {
      position: relative;
      height: 35px;

      .bulkedit-button-holder {
        border-radius: 15px;
        background-color: white;
        display: flex;
        flex-direction: column;
        padding: 20px 10px;

        /* height: 30px; */
        overflow: visible;
        z-index: 30;
        position: absolute;
        top: 0;

        button {
          width: 220px;
          margin-top: 10px;
        }
      }
    }

    .background {
      z-index: 25;
      background-color: rgba(0, 0, 0, 0.58);
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  @media only screen and (max-width: 550px) {
    .find-id {
      justify-content: center;
      flex-wrap: wrap;
      margin-right: 32px;

      input {
        width: 100%;
      }
    }

    #siteidheadercontrols {
      justify-content: center;
      flex-wrap: wrap;

      & > label {
        width: 100%;

        input {
          width: 100%;
        }
      }
    }
  }
}

.inline-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 0;

  .toggle-wrapper {
    margin-bottom: 0;
    margin-right: 10px;
  }
}

.modal-error-message {
  color: red;
  font-weight: bold;
}

.iframeFullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0 none;
  z-index: 9;
}

.iframeNotFullscreen {
  width: 100%;
  height: 1080px;
}

.iframeFullscreenButton {
  position: fixed;
  right: 5px;
  bottom: 5px;
  z-index: 10;
}

.iframeNotFullscreenButton {
  position: relative;
  float: right;
  right: 5px;
  top: 1075px;
}

.hidden {
  display: none;
}
