.page {
  flex-grow: 1;
  width: calc(100% - 40px);
}

.page-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  select {
    width: auto;
  }

  .header-buttons {
    display: flex;

    button {
      margin-left: 10px;
    }
  }
}

.accordion {
  background-color: white;
  border-radius: 15px;
  margin-top: 20px;
  margin-bottom: 10px;
  transition: 0.3s;
  padding: 20px;

  .accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    h3 {
      margin-block-end: 0;
      color: #6e6e82;
      font-size: 20px;
      font-weight: normal;
      font-family: $font-family-medium;
    }

    .date-stamp {
      width: 190px;
      text-align: right;
    }

    button {
      box-shadow: none;
      background-color: $colour_grayscale_light;
    }
  }

  .accordion-body {
    &.collapsed {
      height: 0;
      display: none;
    }

    input,
    textarea,
    select {
      border: 2px solid $colour_grayscale_light;
      background-color: $colour_grayscale_light;
      font-family: $font-family-book;
      font-size: 15px;
      font-weight: normal;

      @media only screen and (max-width: 560px) {
        width: 100%;
      }

      &:focus {
        outline: none;
        border: 2px solid #02a862;
      }

      &.invalid {
        border: 2px solid #ed4646;
      }
    }

    label,
    button {
      @media only screen and (max-width: 560px) {
        width: 100%;
      }
    }
  }
}

.floating-panel {
  display: flex;
  flex-direction: column;
  background:
    transparent linear-gradient(180deg, #f4f5fa 0%, #eeecec 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.16);
  border-radius: 25px;
  padding: 36px;
  margin-bottom: 20px;
  transition-duration: 0.3s;

  .floating-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 50px;

    @media only screen and (max-width: 800px) {
      margin-right: 30px;
    }

    @media (max-width: 560px) {
      margin-right: 0;
    }

    flex-wrap: wrap;
    margin-bottom: 20px;

    .header-title {
      display: flex;
      align-items: center;
    }

    .header-buttons {
      @media (max-width: 560px) {
        width: 100%;
      }

      button {
        @media (max-width: 560px) {
          width: 100%;
        }
      }
    }

    h2 {
      margin-block-end: 20px;
      margin-block-start: 20px;
      color: #6e6e82;
      font-size: 22px;
      font-family: $font-family-medium;
    }

    select {
      margin-top: 15px;
    }

    label {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  .floating-header-2 {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-right: 50px;

    @media only screen and (max-width: 800px) {
      margin-right: 30px;
    }

    @media only screen and (max-width: 560px) {
      margin-right: 0;
    }

    margin-bottom: 20px;

    .header-title {
      display: flex;
      align-items: center;
    }

    h2 {
      margin-block-end: 20px;
      margin-block-start: 20px;
      color: #6e6e82;
      font-size: 22px;
      font-family: $font-family-medium;
    }
  }

  p {
    margin-right: 25px;
  }
}

.file-container {
  //display: flex;
  margin-right: 60px;
  margin-bottom: 60px;
  width: 230px;
  padding: 10px;
  margin-bottom: 50px;
  margin-right: 50px;
  border-radius: 15px;
  font-family: $font-family-medium;
  font-size: 16px;
  color: #707070;
  background-color: #e4e4e4;
  //cursor: pointer;
}

.horizontal-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  & > * {
    @media only screen and (max-width: 560px) {
      width: 100%;
    }

    margin-left: 10px;
  }
}

.horizontal-flex-even {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 35px;

  & > * {
    margin-left: 10px;
    flex-grow: 1;
  }
}

.vertical-flex {
  display: flex;
  flex-direction: column;
}

.initials {
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  align-items: center;
  justify-content: center;
  background: $colour_grayscale_light;
  width: 60px;
  height: 60px;
  border: 2px solid $colour_grayscale_dark;
  border-radius: 50%;
  font-size: 30px;
  margin-right: 10px;
  font-weight: bold;
  color: $colour_grayscale_darkest;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-upload-wrapper {
  font-size: 1.25em;
  font-weight: 700;
  width: 330px;
  color: white;
  background-color: white;
  border-radius: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px dashed #707070;
  margin-top: 18px;

  &.ods-form {
    width: auto !important;
  }

  p {
    font-family: $font-family-book;
    font-size: 15px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .icon.icon-image-upload {
    margin-right: 15px;
  }

  & > input:invalid {
    background-color: red;
  }

  & > input:valid {
    background-color: green;
  }
}

label {
  width: 330px;

  .toggle-wrapper {
    width: 60px;
    height: 34px;
    border: none;
    border-radius: 30px;
    margin-bottom: 16px;
    position: relative;
    flex-shrink: 0;

    .toggle-icon {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 34px;

      &::before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 50%;
      }
    }

    &.invalid {
      .toggle-icon {
        background-color: red;
      }
    }

    input:checked + .toggle-icon {
      background-color: #02a862;

      &.negative {
        background-color: red;
      }
    }

    input:focus + .toggle-icon {
      box-shadow: 0 0 1px #02a862;
    }

    input:checked + .toggle-icon::before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }

    input:disabled + .toggle-icon {
      opacity: 0.5;
    }

    input[type="checkbox"] {
      position: fixed;
      top: -50px;
      left: -100px;
      opacity: 0;
    }
  }
}

.tab-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  margin-bottom: 15px;

  .tab-button {
    background-color: white;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    color: #707070;
    padding: 5px;
    font-size: 16px;
    margin-right: 10px;
    margin-bottom: 10px;
    text-align: center;
    height: 38px;
    display: flex;
    align-items: center;
    width: 150px;
    justify-content: center;
    cursor: pointer;

    &.selected {
      background-color: $colour_grayscale_light;
      box-shadow: none;
      cursor: default;
    }
  }
}

.module-list {
  list-style-type: none;
  margin-bottom: 10px;
  padding-inline-start: 0;

  h4,
  h5 {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: normal;
  }

  ul {
    list-style-type: none;
    margin-bottom: 10px;
    padding-inline-start: 0;
  }

  li {
    margin-left: 50px;
  }

  label {
    width: auto;
  }
}
