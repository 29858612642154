.forecast-page {
  h2 {
    color: #4d4f5c;
    font-size: 24px;
    font-family: $font-family-medium;
    margin-block-end: 20px;
    margin-block-start: 0;
  }

  .leaderboard-rank {
    display: block;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    text-align: center;

    &.rank1 {
      background-color: #02a862;
      font-weight: 500;
    }

    &.rank2 {
      background-color: #ffcd47;
      font-weight: 500;
    }

    &.rank3 {
      background-color: rgb(235, 164, 93);
      font-weight: 500;
    }

    &.opportunity-rank1 {
      background-color: #ed4646;
      font-weight: 500;
    }

    &.opportunity-rank2 {
      background-color: rgb(235, 164, 93);
      font-weight: 500;
    }

    &.opportunity-rank3 {
      background-color: #ffcd47;
      font-weight: 500;
    }
  }

  .menuContainer {
    display: flex;
    flex-wrap: wrap;
  }

  .floating-panel {
    display: flex;
    flex-direction: column;
    background:
      transparent linear-gradient(180deg, #f4f5fa 0%, #eeecec 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.16);
    border-radius: 25px;
    padding: 36px;
    padding-bottom: 0;
    opacity: 0;
    transition-duration: 0.3s;

    @media only screen and (max-width: 735px) {
      padding-right: 11px;
      padding-left: 11px;
    }

    &.loaded {
      opacity: 1;
    }

    .floating-header {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;

      h2 {
        margin-block-end: 0;
        margin-block-start: 0;
        color: #6e6e82;
        font-size: 22px;
        font-family: $font-family-medium;
      }

      p {
        margin-block-start: 10px;
        margin-block-end: 0;
        color: #6e6e82;
        font-size: 15px;
        font-family: $font-family-book;
      }
    }
  }

  .menu-container {
    border: #121213 20px;
    margin-bottom: 120px;
  }

  .dashboard-title {
    text-align: center;
    color: #4d4f5c;
    font-size: 24px;
    font-family: $font-family-medium;
    margin-block-end: 20px;
    margin-block-start: 0;
  }

  .dashboard-container-forecast-accuracy {
    border: 1px solid #000;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #f8f8f8;
    margin-bottom: 50px;
    position: relative;

    .ytd-tooltip {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }

  .emea-filter-container {
    display: flex;
    margin-bottom: 20px;
    flex-direction: row;
    align-items: baseline;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: space-between;
  }

  .emea-chart-container {
    display: flex;
    margin-bottom: 5%;
  }

  .emea-filter-action {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 10px;

    .action {
      &.default {
        background-color: #f00;
        color: #fff;
        margin-left: 5px;
        border: none;
      }
    }
  }

  .react-datepicker__day--keyboard-selected {
    background-color: inherit;
    color: #ccc !important;
    font-weight: inherit;
  }
}

.leaderboard-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  .leaderboard-box {
    flex: 1 1 49%;

    .leaderboard-button {
      height: 30px;
      width: 30px;
      border: none;
      outline: none;
      cursor: pointer;

      img {
        filter: brightness(0.5);
      }
    }
  }
}
