.connect-header {
  width: 100vw;
  background-color: $colour_grayscale_white;
  color: $colour_grayscale_darkest;
  padding: 24px 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 1px 1px 3px 2px #6a6a6a70;
  z-index: 10;

  @media only screen and (max-width: 700px) {
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;

    .left-content {
      justify-content: center;
      margin-bottom: 20px;
    }
  }

  .left-content {
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 0;

    .logo {
      background-image: url("/assets/clear-logo.png");
      height: 75px;
      width: 75px;
      background-repeat: no-repeat;
      background-size: cover;
      flex-shrink: 0;
      align-self: center;
      justify-self: center;
      margin-right: 48px;
    }

    .greeting {
      margin: 12px;
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-weight: 400;
    }
  }

  .center-content {
    display: flex;
    align-content: center;
    justify-content: flex-start;
    flex-grow: 1;
    flex-shrink: 0;

    input {
      background-color: $colour_grayscale_light;
      margin-bottom: 0;
      height: 48px;
      width: 100%;

      &::placeholder {
        font-weight: normal;
      }
    }
  }

  .right-content {
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    text-align: center;

    .submission {
      button {
        width: 200px;
        margin-top: 10px;

        &.logout {
          margin-left: 15px;
        }
      }
    }
  }
}
