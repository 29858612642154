.edit-message-page {
  .header {
    display: flex;
    justify-content: space-between;

    button {
      margin-left: 10px;
    }
  }

  form {
    display: flex;
    flex-direction: column;

    .horizontal-layout {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      div {
        flex-grow: 1;

        &:not(:first-child) {
          margin-left: 10px;
        }
      }
    }

    .vertical-layout {
      margin-right: 10px;
      flex-grow: 1;
    }

    .edit-alert-container {
      .panel-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: -25px;
      }
    }

    .edit-user-group-container {
      label {
        display: flex;
      }
    }
  }
}
