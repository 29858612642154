.connect-weather {
  flex-grow: 1;
  height: calc(100vh - 356px);

  .error-stack {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 170px;
    color: white;
    height: calc(100vh - 300px);

    button {
      margin: 15px;
    }
  }

  .weather-stack {
    flex-grow: 1;
    height: 100%;
    color: white;

    .weather-week {
      padding-top: 20px;
      padding-bottom: 20px;
      display: flex;
      flex-direction: column;
      overflow: auto;
      width: 100%;
    }
  }
}
