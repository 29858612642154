input.float {
  height: 35px;
  border: 2px solid $colour_grayscale_white;
  background-color: $colour_grayscale_white;
  border-radius: 15px;
  font-family: $font-family-medium;
  margin-bottom: 15px;
  padding: 0 20px;
  font-size: 15px;

  &.invalid {
    border: 2px solid $colour_bad;
  }

  &:disabled {
    opacity: 0.5;
    box-shadow: none;
    cursor: default;
  }
}

input,
select {
  height: 35px;
  width: 330px;
  border: none;
  background: white;
  color: #6f6f83;
  display: block;
  border-radius: 15px;
  font-family: $font-family-book;
  margin-bottom: 15px;
  padding: 0 20px;
  border: 2px solid white;
  font-size: 15px;
  font-weight: bold;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  &.invalid {
    border: 2px solid $colour_bad;
  }
}

textarea {
  display: block;
  width: 330px;
  height: 123px;
  border-radius: 15px;
  border-radius: 15px;
  background-color: white;
  color: $colour_grayscale_darkest;
  border: none;
  font-family: $font-family-book;
  font-size: 15px;
  font-weight: bold;
  padding: 10px;
  box-sizing: border-box;
  resize: vertical;

  &.invalid {
    border: 2px solid $colour_bad;
  }
}

label {
  margin-bottom: 4px;
  line-height: 40px;
  color: #7f7f7f;
  font-family: $font-family-book;
  font-size: 15px;
  display: block;
}

input[type="checkbox"].toggle {
  //-webkit-appearance: none;
  height: 35px;
  width: 15px;
  border: 2px solid $colour_grayscale_white;
  background-color: $colour_grayscale_white;
  color: $colour_grayscale_darkest;
  border-radius: 15px;
  font-family: $font-family-2;
  margin-bottom: 15px;
  margin-right: 315px;
  padding: 0 20px;
  font-size: 16px;
  line-height: 10px;
  display: block;

  &.invalid {
    border: 2px solid $colour_bad;
  }
}

input[type="checkbox"] {
  //-webkit-appearance: none;
  height: 15px;
  width: 15px;
  border: 2px solid $colour_grayscale_white;
  background-color: $colour_grayscale_white;
  color: $colour_grayscale_darkest;
  border-radius: 15px;
  font-family: $font-family-2;
  margin-bottom: 15px;
  margin-right: 16px;
  padding: 0 20px;
  font-size: 16px;
  line-height: 10px;
  display: inline-block;

  &.invalid {
    border: 2px solid $colour_bad;
  }
}

input[type="radio"] {
  height: 20px;
  width: 20px;
  border: 2px solid $colour_grayscale_white;
  background-color: $colour_grayscale_white;
  color: $colour_grayscale_darkest;
  display: inline-block;
  border-radius: 15px;
  font-family: $font-family-2;
  margin-bottom: 15px;
  margin-right: 16px;
  padding: 0 20px;
  font-size: 16px;
  line-height: 10px;

  &.invalid {
    border: 2px solid $colour_bad;
  }
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
  border: 2px solid #02a862;
}

input:disabled,
textarea:disabled,
select:disabled {
  background-color: transparent;
  border: 2px solid $colour_grayscale_white;
  color: #9c9c9c;
}

label {
  &.wrapping {
    display: flex;
    align-items: center;
    width: auto;

    h4,
    h5,
    span {
      margin-left: 10px;
    }

    .toggle-wrapper {
      width: 40px;
      height: 25px;
      border: none;
      border-radius: 25px;
      margin-bottom: 0;
      position: relative;
      flex-shrink: 0;

      .toggle-icon::before {
        position: absolute;
        content: "";
        height: 21px;
        width: 21px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        transition: 0.4s;
        border-radius: 50%;
      }

      input:checked + .toggle-icon::before {
        transform: translateX(15px);
      }
    }
  }
}

.success-message {
  color: #00643c;
}
