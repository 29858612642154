.content-builder.page {
  .content-panel {
    .content-headers {
      margin-right: 20px;
    }

    textarea {
      width: 100%;
    }

    .big-content {
      width: 100%;
    }
  }

  .audience-panel {
    .floating-header {
      flex-wrap: nowrap;
    }
  }

  .label-list {
    label {
      display: flex;
      justify-content: space-between;
    }
  }

  .schedule-headers {
    margin-right: 20px;
  }

  .MuiFormControl-root {
    .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
      &::before,
      &::after {
        display: none;
      }
    }

    .MuiInputBase-input.MuiInput-input.Mui-disabled {
      background-color: transparent;
      border: 2px solid #fff;
      color: #9c9c9c;
    }

    &.invalid {
      input.MuiInputBase-input.MuiInput-input {
        border: 2px solid #ed4646;
      }
    }

    .MuiInputBase-input.MuiInput-input {
      height: 35px;
      width: 330px;
      cursor: pointer;
      border: none;
      background: white;
      color: #6f6f83;
      display: block;
      border-radius: 15px;
      font-family: $font-family-book;
      margin-bottom: 15px;
      padding: 0 20px;
      border: 2px solid white;
      font-size: 15px;
      font-weight: bold;
      -ms-box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;

      &.invalid {
        border: 2px solid $colour_bad;
      }
    }
  }

  .horizontal-stack {
    display: flex;
    flex-wrap: wrap;
  }

  .template {
    margin: 0;
    padding: 0;
  }

  .tab-container {
    margin-bottom: 0;
    padding: 12px 0;

    .tab-button {
      width: 170px;
      border: 2px solid transparent;
      height: 52px;
      justify-content: space-around;
      box-shadow: none;

      &:last-child {
        margin-right: 0;
      }

      &.invalid {
        border: 2px solid #ed4646;
      }

      &.selected {
        border: 2px solid #02a862;
        background-color: white;
        box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.16);
      }
    }
  }
}

.MuiDialog-root {
  .MuiPickersToolbar-toolbar,
  .MuiPickerDTTabs-tabs,
  .MuiPickersDay-daySelected,
  .MuiPickersClock-pin,
  .MuiPickersClockPointer-pointer,
  .MuiPickersClockPointer-noPoint,
  .MuiPickersDay-daySelected:hover {
    background-color: #02a862;
  }

  .MuiPickersClockPointer-thumb {
    border-color: #02a862;
  }

  .PrivateTabIndicator-colorSecondary-7,
  .PrivateTabIndicator-colorSecondary-3 {
    background-color: #00643c;
  }

  .MuiButton-textPrimary {
    color: #02a862;
  }
}
