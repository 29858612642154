@import '../../../../style/variables.scss';

.ot-status-dropdown {
  select.coloured-dropdown {
    &.background-color-1,
    &.background-color-2 {
      background-color: #ed464680;
    }

    &.background-color-3 {
      background-color: #ffcc0080;
    }

    &.background-color-4,
    &.background-color-5 {
      background-color: #0ed98880;
    }
  }
}
