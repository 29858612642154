.page.pending-category-review {
  .search-wrapper {
    display: flex;

    input {
      margin-right: 15px;
    }
  }

  .table-body {
    tr {
      td {
        &:last-child {
          width: 200px;
        }
      }
    }
  }
}
