.kpi-filter-container {
  display: flex;
  margin-bottom: 20px;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-between;
  margin-left: 3%;
}

.kpi-filter-action {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 10px;

  .action {
    &.default {
      background-color: #f00;
      color: #fff;
      margin-left: 5px;
      border: none;
    }
  }
}

.kpi-table-container {
  margin-top: 2% !important;
  margin-bottom: 2% !important;
  overflow: auto !important;
}

.kpi-table-container .table-style {
  width: 100% !important;
  table-layout: auto !important;
}

.kpi-table-container .table-style td {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}
