.pagination {
  font-size: 13px;
  font-family: $font-family-medium;
  color: #4d4f5c;
  width: 190px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media only screen and (max-width: 560px) {
    margin-top: 70px;
  }

  button {
    box-shadow: 0 2px 6px #00000029;
    background-color: #f7f7f7;
    font-weight: bold;
    color: #c3c3c3;
    border: none;
    border-radius: 8px;
    height: 30px;
    width: 30px;
    margin-left: 5px;
    font-size: 20px;
    cursor: pointer;
    font-family: "Roboto", sans-serif;

    &:disabled {
      cursor: default;
      color: #f0f0f0;
      box-shadow: none;
    }
  }
}
