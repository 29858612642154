.edit-task-page {
  .header {
    display: flex;
    justify-content: space-between;

    button {
      margin-left: 10px;
    }
  }

  form {
    display: flex;
    flex-direction: column;

    .horizontal-layout {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .accordion {
        flex-grow: 1;

        &:not(:first-child) {
          margin-left: 10px;
        }
      }
    }

    .vertical-layout {
      margin-right: 10px;
      flex-grow: 1;

      .new {
        width: 40px;
        height: 56px;
        margin-left: 13px;
        margin-right: 15px;
      }

      .documents-wrapper {
        margin: 0 0;
        margin-bottom: 28px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: flex-end;

        .file-title {
          max-height: 40px;
          overflow-wrap: break-word;
          overflow: hidden;
          text-align: center;
        }
      }

      .document-details {
        padding: 50px;
        border-radius: 10px;
        box-shadow: 1px 1px 6px rgb(0 0 0 / 16%);
        position: relative;
        width: 250px;
        height: 250px;

        .icon-file {
          width: 125px;
          height: 45px;
          margin-top: 10px;
          margin-left: 13px;
          margin-right: 15px;
        }

        .form-buttons {
          padding-left: 0 !important;
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin-top: 10px;

          .action {
            &.download {
              height: 40px;
              width: 40px;
              border-radius: 50%;
              cursor: pointer;

              div {
                background-image: url('/assets/icons/Download light.svg');
                width: 24px;
                margin-left: 1px;
                height: 30px;
              }
            }
          }
        }
      }
    }

    .edit-alert-container {
      .panel-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: -25px;
      }
    }

    .edit-user-group-container {
      label {
        display: flex;
      }
    }
  }
}

.assigned-matrix-page {
  margin-top: 20px;

  .no-records {
    text-align: center;
  }

  .table-header {
    &.wrap {
      flex-wrap: wrap;
      overflow: auto;

      .flex-wrap {
        display: flex;
        flex-wrap: wrap;
      }
    }

    input,
    select {
      background: white;
    }
  }

  .send-reminder-all {
    width: fit-content;
    margin-top: 20px;
  }

  .clear {
    align-self: center;
    margin-top: 20px;
  }

  .search {
    align-self: center;
    margin-top: 20px;
    margin-right: 10px;
  }

  .margin-right {
    margin-right: 10px;
  }
}
