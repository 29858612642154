.shipment-filter-container {
  width: 100%;
  margin-left: 3%;
}

.shipment-performance-chart {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: stretch;
  justify-content: space-around;
  align-items: baseline;
}

.shipment-table-container {
  overflow: auto !important;
}

.shipment-table-container .table-style {
  width: 100% !important;
  table-layout: auto !important;
}

.shipment-table-container .table-style td {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}
