@import "../../../style/variables";

.bulkupdate-container {
  width: 100vw;

  .accordion {
    .accordion-header {
      cursor: default;
    }
  }

  .search-fields {
    display: flex;
    flex-wrap: wrap;

    > label {
      margin-right: 20px;
    }
  }

  .search-results {
    max-height: 300px;
    overflow-y: auto;
  }

  .save-result {
    align-self: center;
  }

  .bulkupdate-form {
    :invalid,
    :invalid:focus,
    input:invalid:focus select:invalid:focus {
      border: none;
      box-shadow: none;
    }

    &.submitted {
      :invalid,
      :invalid:focus,
      input:invalid:focus select:invalid:focus {
        border: 2px solid $colour_bad;
        box-shadow: none;
      }
    }
  }

  .progress-stages {
    .progress-stage {
      display: flex;

      label:last-child {
        align-self: flex-end;
        padding-left: 10px;
        width: initial;
      }
    }
  }

  .action-container {
    display: flex;
    flex-direction: row-reverse;

    .action {
      margin: 0 5px;
    }
  }

  .layout-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .search-container {
    width: calc(50% - 10px);
    margin-right: 10px;
    display: flex;
    flex-direction: column;

    .search-option {
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;

      select {
        margin-bottom: 0;
      }

      .action {
        align-self: flex-end;
        margin-left: 10px;
        margin-bottom: 5px;
      }
    }

    .selected-orders {
      flex-grow: 1;

      .table-body {
        margin-top: 20px;
      }
    }
  }

  .edit-container {
    width: calc(50% - 10px);
    margin-left: 10px;
  }

  .modal-blocker .modal {
    width: 75vw;
    border-radius: 10px;
    background-color: $colour_grayscale_white;

    .modal-body {
      .preview-changes {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;

        > div {
          margin-right: 20px;
        }
      }
    }

    table {
      th:first-child,
      th:last-child {
        border-radius: 10px 10px 0 0;
      }

      .spacer {
        background-color: #fff;
        width: 10px;
      }
    }

    input:disabled {
      background-color: $colour_grayscale_light;
    }

    .modal-buttons {
      display: flex;
      flex-direction: row-reverse;

      .action:first-child {
        margin-left: 20px;
      }
    }
  }
}
