.task-admin-page {
  .task-admin-header {
    display: flex;
    justify-content: space-between;

    button {
      margin-left: 10px;
    }
  }

  .accordion-header {
    flex-wrap: wrap;

    .horizontal-flex {
      justify-content: flex-end;
    }
  }
}
