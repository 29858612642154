.chart-container {
  display: flex;
  justify-content: space-between;

  .recharts-responsive-container {
    width: 100% !important;
  }

  .chart {
    flex-direction: column;
    width: 100%;
  }
}

.order-table-container {
  margin-top: 30px !important;
  margin-bottom: 5%;

  td,
  th {
    text-align: center;
  }

  .table {
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }
}

.orderMetrics-filter-container {
  display: flex;
  margin-bottom: 20px;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-between;
  margin-left: 3%;
}

.export-button-style {
  margin: 2% 0% 2% 5%;
}

.text-style {
  text-align: center !important;
}
