.instant-messaging-admin-page {
  .chat-room-container {
    display: flex;
    flex-wrap: wrap;
  }

  .floating-body {
    display: flex;
  }

  .conversation-list {
    margin-right: 15px;

    .queued-user,
    .conversation-user {
      display: flex;
      align-items: center;
      background-color: white;
      border: 2px solid white;
      border-radius: 15px;
      margin-bottom: 10px;
      width: 330px;
      height: 75px;
      cursor: pointer;
      box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.16);

      &.selected {
        box-shadow: none;
      }

      &.empty {
        border: 2px dashed #6e6e82;
        background: none;
        box-shadow: none;
        cursor: default;
      }

      h2 {
        margin-block-start: 10px;
        width: 40px;
        color: #6e6e82;
        margin-left: 14px;
        margin-right: 14px;
        text-align: center;
      }

      .icon {
        width: 40px;
        margin-left: 14px;
        margin-right: 14px;
      }
    }
  }
}

.conversation {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 500px;

  .status {
    text-align: center;
    margin-top: 200px;
  }

  .conversation-text {
    flex-grow: 1;
    padding: 15px;
    background-color: white;
    margin-bottom: 15px;
    border-radius: 15px;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.16);
    overflow: auto;
    height: 620px;

    .message {
      display: flex;
      margin-bottom: 15px;

      .avatar {
        display: flex;
        flex-shrink: 0;
        flex-grow: 0;
        align-items: center;
        justify-content: center;
        background: #f4f2f2;
        width: 60px;
        height: 60px;
        margin-right: 5px;
        margin-bottom: 5px;
        border: 2px solid #bbb;
        border-radius: 50%;
        font-size: 30px;
        font-weight: bold;
        color: #707070;

        .icon {
          width: 40px;
          margin-left: 14px;
          margin-right: 14px;
        }
      }

      &.receiver {
        justify-content: flex-start;

        .message-text-container {
          background-color: #0080002b;

          &::after {
            left: 0;
            border-right-color: #0080002b;
            border-left: 0;
            margin-left: -15px;
          }
        }
      }

      &.sender {
        justify-content: flex-start;
        flex-direction: row-reverse;

        .message-text-container {
          background-color: #3ac1d442;

          &::after {
            right: 0;
            border-left-color: #3ac1d442;
            border-right: 0;
            margin-right: -15px;
          }
        }
      }

      .message-text-container {
        min-height: 60px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-evenly;
        margin: 0 15px;
        position: relative;
        width: 50%;
        border-radius: 15px;
        padding: 10px 20px;

        .message-timestamp {
          font-size: 12px;
          align-self: flex-end;
        }

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          width: 0;
          height: 0;
          border: 15px solid transparent;
          border-top: 0;
          margin-top: -7.5px;
        }
      }
    }
  }

  .conversation-controls {
    display: flex;
    flex-shrink: 0;
    flex-grow: 0;

    input {
      flex-grow: 1;
      box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.16);
    }

    button {
      height: 35px;
      margin-left: 10px;
    }
  }
}

.instant-messaging-archive-page {
  .header-buttons {
    display: flex;
    flex-wrap: wrap;

    @media only screen and (max-width: 560px) {
      margin-left: 0;
      width: 100%;
    }

    input,
    button.action {
      margin-bottom: 15px;
      margin-left: 15px;

      @media only screen and (max-width: 560px) {
        margin-left: 0;
        width: 100%;
      }
    }
  }

  tr {
    cursor: pointer;
  }
}
