.floating-panel.google-panel {
  padding: 0;
  transition-duration: 0.3s;
  width: 100%;
  flex-grow: 1;
  position: relative;

  &.full-screen {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    right: 0;
    border-radius: 0;
    z-index: 2;

    #google-map {
      border-radius: 0;
    }
  }

  .bottom-right-buttons {
    position: absolute;
    bottom: 70px;
    right: 10px;
    z-index: 1;

    button {
      border: none;
      box-shadow: rgba(0, 0, 0, 0.3) 0 1px 4px -1px;
      background-color: white;
      padding: 6px 0;
      cursor: pointer;
    }
  }

  #siteidmapcontrols {
    position: absolute;
    z-index: 1;
    display: flex;
    top: 8px;
    right: 0;

    @media only screen and (max-width: 450px) {
      width: calc(100% - 10px);

      input,
      label {
        width: 100%;
        flex-shrink: 1;
      }

      button {
        flex-shrink: 0;
      }
    }

    input,
    label,
    button {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  #google-map {
    border-radius: 25px;
    height: 100%;
    min-height: 400px;
  }

  #first-button {
    width: 115px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    float: left;
  }

  #second-button {
    margin-left: 2px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-image: url(/assets/orderly/icons/PO.svg);
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: 35% 45%;
    position: absolute;
    float: left;
    margin-right: 5px;
  }

  .address-wrapper {
    margin-right: 25px;
  }
}
