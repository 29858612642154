@import "../../style/variables.scss";

.modal-blocker {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  .modal {
    background-color: #e4e4e4;
    padding: 15px;
    padding-bottom: 40px;
    padding-left: 80px;
    padding-right: 80px;
    max-width: 95%;

    .modal-menu {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;

      button {
        width: 30px;
        margin-right: -60px;
        background-color: #e4e4e4;
        box-shadow: none;
        font-size: 20px;
        border: none;
        color: #bbb;
      }
    }

    .modal-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h2 {
        font-family: $font-family-medium;
        font-size: 24px;
        color: #53536b;
      }
    }

    .modal-body {
      margin-top: 20px;
      margin-bottom: 25px;

      p {
        color: #53536b;
        font-family: $font-family-book;
        font-size: 18px;
        text-align: center;
      }

      label {
        width: 100%;

        input,
        textarea {
          width: 100%;
        }
      }
    }

    .modal-buttons {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 15px;

      &.reminders {
        flex-direction: row;
      }

      button {
        width: 314px;
        height: 50px;
        font-size: 20px;
        border-radius: 25px;
        margin-bottom: 20px;
        font-family: $font-family-medium;
        font-weight: normal;
      }
    }
  }
}
