.contact-us-page {
  width: 100%;
  background-color: #3ac1d4;
  background-image: url(/assets/orderly/award.png);
  background-size: cover;
  min-height: 100vh;
  position: absolute;

  .orderly-menu {
    .button-bar {
      @media only screen and (max-width: 1175px) {
        display: block;
      }
    }
  }

  .floating-modal {
    background-color: white;
    border-radius: 35px;
    max-width: 700px;
    margin: 0 auto;
    margin-top: 230px;
    margin-bottom: 30px;
    padding: 35px;
    padding-bottom: 15px;

    h3,
    p {
      text-align: center;
    }

    .form-body {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      input {
        border: 2px solid $colour_grayscale_light;

        &.invalid {
          border: 2px solid #ed4646;
        }
      }
    }

    .form-buttons {
      display: flex;
      flex-direction: column;
      align-items: center;

      button {
        width: 200px;
        margin-bottom: 10px;

        &:last-child {
          background: none;
          box-shadow: none;
          color: black;
        }
      }
    }

    .form-buttons-2 {
      display: flex;
      flex-direction: column;
      align-items: center;

      button {
        width: 200px;
        margin-bottom: 10px;
      }
    }
  }
}
