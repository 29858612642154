.rslperformance-page {
  .floating-panel {
    margin-bottom: 20px;

    .rslperformance-header {
      display: flex;
      justify-content: space-between;
      margin-right: 25px;
      flex-wrap: wrap;

      .icon-stack {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        h2 {
          margin-block-end: 0;
          margin-block-start: 0;
          color: #6e6e82;
          font-size: 22px;
          font-family: $font-family-medium;
        }
      }

      .header-buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap-reverse;

        @media (max-width: 560px) {
          justify-content: center;
          width: 100%;
        }

        select {
          margin-top: 15px;

          @media (max-width: 560px) {
            width: 100%;
          }
        }

        button {
          margin-left: 10px;
        }
      }
    }

    .pagination-controls {
      font-size: 13px;
      font-family: $font-family-medium;
      color: #4d4f5c;
      width: 180px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @media (max-width: 560px) {
        width: 100%;
      }

      button {
        background-color: #ebeaea;
        font-weight: bold;
        color: #c3c3c3;
        border: none;
        border-radius: 3px;
        height: 30px;
        width: 30px;
        margin-left: 5px;
        font-size: 20px;
        cursor: pointer;

        &:disabled {
          cursor: default;
          color: #f0f0f0;
        }
      }
    }

    p {
      margin-block-start: 10px;
      margin-right: 25px;
      margin-block-end: 0;
      color: #6e6e82;
      font-size: 15px;
      font-family: $font-family-book;
    }
  }

  .rsl-header {
    font-size: 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    color: #7f7f7f;
    flex-grow: 1;
    font-family: $font-family-book;

    & > div {
      flex-grow: 1;
    }

    label {
      font-size: 24px;
      font-family: $font-family-medium;
      min-width: 300px;
    }
  }

  .rslperformance-container {
    .row {
      display: flex;
      flex-wrap: wrap;
      background-color: $colour-grayscale-white;
      border-radius: 25px;
      margin-bottom: 20px;
      padding: 18px 24px;

      .rsl-table-container {
        overflow-x: auto;
        width: 100%;
      }

      .label-value-pair {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 5px;
        flex-wrap: wrap;

        label {
          margin-right: 10px;
          line-height: normal;
          min-width: 250px;
          color: #5d5a5a;

          @media only screen and (max-width: 560px) {
            width: auto;
          }
        }

        span {
          font-size: 22px;
        }
      }
    }
  }

  .bad {
    background-color: $colour-bad;
    color: $colour-grayscale-white;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
    text-align: right;
    display: block;
    padding: 10px 20px;

    label {
      color: $colour-grayscale-white;
    }
  }

  .good {
    background-color: $colour-good;
    color: $colour-grayscale-white;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
    text-align: right;
    display: block;
    padding: 10px 20px;

    label {
      color: $colour-grayscale-white;
    }
  }
}
