.notifications {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  button {
    margin-bottom: 45px;

    @media only screen and (max-width: 1560px) {
      margin-bottom: 15px;
      width: 80px;
      height: 40px;
    }
  }

  .button-holder {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 5px;
  }

  .notifications-list {
    margin-top: 43px;
    overflow: auto;
    align-self: stretch;

    @media only screen and (max-width: 1560px) {
      overflow: hidden;
    }
  }
}
