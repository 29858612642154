.ytd-forecast-wrapper {
  display: flex;
  justify-content: space-around;
  width: 70%;
  padding-top: 20px;
  padding-bottom: 30px;
  margin: 0 auto;

  @media only screen and (max-width: 975px) {
    width: 100%;
  }

  @media only screen and (max-width: 630px) {
    flex-wrap: wrap;
  }

  h3 {
    margin-bottom: 0;
  }

  .forecast-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 120px;
    justify-content: space-between;

    @media only screen and (max-width: 630px) {
      margin-bottom: 50px;
    }

    .trend {
      align-self: flex-end;

      .trend-arrow {
        width: 60px;
        height: 60px;
      }
    }

    .percentage {
      font-weight: bold;
      font-size: 50px;
      margin-top: -15px;
    }

    .difference-metric {
      font-size: 25px;
      margin-top: 15px;
    }
  }
}
