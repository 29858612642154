.header-panel {
  &.floating-panel {
    padding: 10px;
    padding-left: 30px;

    .icon {
      margin-left: 0;
    }

    h2 {
      margin: 0;
      margin-top: 10px;
    }

    .floating-header {
      margin-bottom: 0;

      .icon-panel {
        display: flex;
        align-items: center;
      }
    }
  }
}
