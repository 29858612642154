.digital-asset-management-page {
  .floating-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    button {
      margin-left: 10px;
    }
  }

  .icon-title {
    display: flex;
    flex-direction: row;
  }

  .documents-management-body {
    margin-top: 20px;

    .documents-management-breadcrumbs {
      display: flex;
      flex-direction: column;

      .documents-management-breadcrumb {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        background-color: #fff;
        align-items: center;
        border-radius: 10px;
        padding: 10px;
        margin-bottom: 10px;

        .button-container {
          display: flex;
          flex-wrap: wrap;

          button.edit {
            margin-left: 10px;
            width: 150px;
            box-shadow: none;
            background-color: $colour_grayscale_light;
          }
        }

        button.upload,
        button.add-folder {
          display: none;
        }

        h3 {
          margin-block-end: 0;
          word-break: break-word;
        }

        &:last-child {
          margin-bottom: 0;
          border-radius: 10px 10px 0 0;

          button.upload,
          button.add-folder {
            display: block;
          }

          button.view {
            display: none;
          }
        }
      }
    }

    .documents-management-holder {
      display: flex;
      flex-wrap: wrap;
      padding: 15px;
      background-color: $colour_grayscale_white;
      border-radius: 0 0 10px 10px;
      border: none;

      .file-container {
        //pulled from shared layout

        @media only screen and (max-width: 1980px) and (min-width: 1426px) {
          width: calc(25% - 64px);

          &:nth-child(4n) {
            margin-right: 0;
          }
        }

        @media only screen and (max-width: 1425px) and (min-width: 1121px) {
          width: calc(33% - 58px);

          &:nth-child(3n) {
            margin-right: 0;
          }
        }

        @media only screen and (max-width: 1120px) and (min-width: 660px) {
          width: calc(50% - 43px);
          margin-right: 20px;

          &:nth-child(2n) {
            margin-right: 0;
          }
        }

        @media only screen and (max-width: 660px) {
          width: 100%;
          margin-right: 0;
          margin-bottom: 20px;
        }
      }

      .preview {
        max-width: 50%;
      }

      .file-details {
        label {
          width: unset;
          font-weight: bold;
          word-break: break-word;

          span {
            font-weight: normal;
          }
        }
      }
    }
  }

  a.edit {
    height: 30px;
    font-weight: bold;
    font-size: 12px;
    border-radius: 5px;
    border: none;
    font-family: "Roboto", sans-serif;
    transition-duration: 0.3s;
    color: #02a862;
    cursor: pointer;
    margin-left: 10px;
    width: 150px;
    box-shadow: none;
    background-color: #f4f2f2;
    line-height: 28px;
    text-align: center;
  }
}
