.period-filter-container {
  display: flex;
  margin-bottom: 20px;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-between;
}

.width-style {
  width: 300px !important;
}

.dropdown-style {
  width: 300px !important;
  margin-left: 3% !important;
}

.c-input {
  width: 250px !important;
  margin-bottom: 0% !important;
}

@media screen and (max-width: 900px) {
  .period-filter-container {
    flex-direction: column;
  }

  .dropdown-content {
    position: relative;
  }

  .dropdown,
  .dropbtn {
    width: 100%;
    display: block;
  }
}
