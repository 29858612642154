.filter-panel {
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 10px;

  &.full-screen {
    position: absolute;
    z-index: 2;
    bottom: 10px;
    left: 10px;
    right: 215px;
    margin: 0;

    &.collapsed {
      right: unset;
      height: 60px;

      label {
        display: none;
      }

      .multi-select-siteid {
        display: none;
      }
    }
  }

  .map-toggle.closed,
  .map-toggle.expanded {
    width: 30px;
    height: 30px;
    background-color: #02a862;
    color: white;
    font-weight: bold;
    border-radius: 20px;
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    border: none;
    margin: 5px;
    box-shadow: lightgrey 0 0 10px;
    transition-duration: 0.3s;
    cursor: pointer;
  }

  .icon-siteid-filter {
    width: 25px;
    height: 25px;
    margin: 7px;
  }

  .closed {
    transform: rotate(45deg);
  }
}
