@import '../../../style/variables';

.search-orders-page {
  width: 100%;

  .accordion {
    .accordion-header {
      cursor: default;
    }
  }

  .search-order-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;

    .group-details {
      width: calc(50% - 10px);

      @media screen and (max-width: 991px) {
        width: 100%;
      }

      &:nth-child(odd) {
        margin-right: 20px;
      }
    }

    .download-container {
      width: min-content;

      &.summary {
        width: 235px;
        padding-left: 30px;
        margin-left: 20px;
      }

      .accordion-body {
        height: calc(100% - 20px);
      }
    }
  }

  .button-container {
    display: flex;
    justify-content: flex-end;

    .action {
      margin-left: 10px;
    }
  }

  .export {
    display: flex;
    justify-content: center;

    @media only screen and (max-width: 560px) {
      margin-bottom: 15px;
      margin-left: 0;
    }

    .icon {
      width: 25px;
      height: 25px;
      margin-top: 3px;
    }
  }

  .submitted {
    :invalid,
    :invalid:focus {
      border: 2px solid $colour_bad;
      box-shadow: none;
    }
  }

  .no-records {
    text-align: center;
  }
}
