.store-selector {
  position: relative;

  @media (max-width: 560px) {
    width: 100%;
  }

  input,
  select {
    margin-bottom: 0;

    @media (max-width: 560px) {
      width: 100%;
    }
  }

  .no-suggestions {
    color: #999;
    padding: 0.5rem;
  }

  .suggestions {
    background-color: white;
    border-radius: 15px;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 50vh;
    overflow-y: auto;
    padding-left: 0;
    position: absolute;
    right: 0;
    top: 35px;
    line-height: 22px;
    z-index: 19;

    @media (max-width: 560px) {
      width: 100%;
    }
  }

  .suggestions li {
    padding: 0.5rem;
  }

  .suggestion-active,
  .suggestions li:hover {
    background-color: #02a862;
    color: white;
    cursor: pointer;
    font-weight: 700;
  }
}
