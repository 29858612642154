.ordertracker-dashboard-page {
  .floating-panel {
    .floating-header {
      justify-content: flex-start;

      @media screen and (min-width: 992px) {
        flex-wrap: nowrap;
      }

      .vertical-controls {
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;

        > * {
          margin: 0 10px;
        }

        .input-delayed {
          margin-top: 15px;
        }
      }
    }
  }

  .stat-container {
    .stat-group {
      width: 100%;
      max-width: 500px;
      margin: 10px;
    }
  }
}
