.distribution-info {
    font-size: 0.8em;
    margin-top: 20px;

    &.error {
        color: red;
    }
}

.table-apac {
    text-align: left;
    margin-top: 20px;

    th {
        width: 25%;
    }
}

.label {
    &.apac {
        margin-top: -11px;
        margin-left: 20px;
    }
}

.header-title {
    &.apac {
        h4 {
            margin-bottom: 20px;
        }
    }
}

select {
    &.apac {
        margin-bottom: 0;
    }
}

.distribution-info-header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}