button.float {
  width: 112px;
  height: 50px;
  border-radius: 30px;
  font-size: 16px;
  border: none;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  font-family: $font-family-medium;
  color: white;
  background-color: $colour_SBgreen_light;
  cursor: pointer;
  transition-duration: 0.3s;

  &:hover,
  &:active,
  &:focus {
    transition-duration: 0.3s;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  }

  &.bad {
    background-color: #d3372a;
  }

  &:disabled {
    opacity: 0.5;
    box-shadow: none;
    cursor: default;
  }
}

button.action {
  width: 160px;
  height: 35px;
  font-weight: normal;
  font-size: 16px;
  border-radius: 17px;
  border: 1px solid $colour_grayscale_dark;
  font-family: $font-family-medium;
  transition-duration: 0.3s;
  color: white;
  background-color: $colour_SBgreen_light;
  box-shadow: 0 3px 6px #00000029;
  cursor: pointer;

  @media only screen and (max-width: 560px) {
    width: 100%;
    margin-top: 15px;
    margin-right: 0;
  }

  &.negative {
    background-color: #d3372a;
  }

  &:hover,
  &:active,
  &:focus {
    transition-duration: 0.3s;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  }

  &:disabled {
    opacity: 0.5;
    box-shadow: none;
    cursor: default;
  }

  &.square {
    width: 35px;
    height: 35px;
    margin-right: 7px;
    margin-left: 3px;
    margin-top: 25px;
    padding: 0;
  }
}

button.page {
  padding: 0 14px;
  height: 30px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 12px;
  border: 1px solid $colour_highlight;
  font-family: $font-family-medium;
  transition-duration: 0.3s;
  color: $colour_highlight;
  background-color: $colour_grayscale_white;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    transition-duration: 0.3s;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  }
}

button.back {
  padding: 0 14px;
  height: 30px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 12px;
  border: none;
  transition-duration: 0.3s;
  font-family: $font-family-medium;
  color: $colour_grayscale_white;
  background-color: $colour_highlight;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    transition-duration: 0.3s;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  }
}

button.link,
a {
  font-weight: bold;
  font-family: $font-family-light;
  border: none;
  box-shadow: none;
  color: $colour_grayscale_darkest;
  background: none;
  text-decoration: none;
  padding: 1px 20px;
  cursor: pointer;

  &.logout {
    color: $colour_grayscale_dark;
    font-size: 14px;
    padding: 5px 0;
    text-align: left;
    width: 200px;
  }
}

button.edit {
  width: 100px;
  height: 30px;
  font-weight: bold;
  font-size: 12px;
  border-radius: 5px;
  border: none;
  font-family: $font-family-medium;
  transition-duration: 0.3s;
  color: $colour_SBgreen_light;
  background-color: $colour_grayscale_white;
  box-shadow: 0 3px 6px #00000029;
  cursor: pointer;

  &:disabled {
    opacity: 0.75;
    cursor: auto;
  }

  &.positive {
    background-color: #02a862;
  }

  &.negative {
    color: $colour_bad;
  }
}
