.tile {
  width: 330px;
  height: 120px;
  background:
    transparent linear-gradient(180deg, #f5f6fa 0%, #f5f5f5 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.16);
  border-radius: 15px;
  margin-right: 40px;
  margin-bottom: 40px;
  display: flex;
  cursor: pointer;
  align-items: center;
  position: relative;
  overflow: hidden;

  &.disabled {
    background:
      transparent
      linear-gradient(
        180deg,
        rgba(245, 246, 250, 0.75) 0%,
        rgba(245, 245, 245, 0.75) 100%
      )
      0% 0% no-repeat padding-box;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.16);
    cursor: default;
  }

  @media only screen and (max-width: 1980px) and (min-width: 1921px) {
    width: calc(25% - 30px);

    &:nth-child(4n) {
      margin-right: 0;
    }
  }

  @media only screen and (max-width: 1920px) and (min-width: 1533px) {
    width: calc(33% - 24px);

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  @media only screen and (max-width: 1532px) and (min-width: 1241px) {
    width: calc(33% - 24px);

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  @media only screen and (max-width: 1240px) and (min-width: 1051px) {
    width: calc(33% - 11px);
    margin-right: 20px;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  @media only screen and (max-width: 1050px) and (min-width: 736px) {
    width: calc(50% - 10px);
    margin-right: 20px;

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  @media only screen and (max-width: 735px) {
    width: calc(100%);
    margin-right: 0;
    margin-bottom: 20px;
  }

  .new-tag {
    &::before {
      background-color: #61c3df;
      content: "New";
      color: white;
      font-family: $font-family-light;
      font-size: 13px;
      padding: 2px 20px;
      position: absolute;
      right: -16px;
      top: 8px;
      transform: rotate(45deg);
    }
  }

  .coming-soon-tag {
    &::before {
      background-color: #ffca83;
      content: "Coming Soon";
      color: white;
      font-family: "Roboto", sans-serif;
      font-size: 13px;
      padding: 2px 20px;
      position: absolute;
      right: -24px;
      top: 25px;
      transform: rotate(45deg);
      opacity: 1;
    }
  }

  .active-tag {
    &::before {
      background-color: #02a862;
      content: "Online";
      color: white;
      font-family: "Roboto", sans-serif;
      font-size: 13px;
      padding: 2px 40px;
      position: absolute;
      right: -32px;
      top: 16px;
      transform: rotate(45deg);
      opacity: 1;
    }
  }

  .inactive-tag {
    &::before {
      background-color: $colour_bad;
      content: "Offline";
      color: white;
      font-family: "Roboto", sans-serif;
      font-size: 13px;
      padding: 2px 40px;
      position: absolute;
      right: -32px;
      top: 16px;
      transform: rotate(45deg);
      opacity: 1;
    }
  }

  .tile-text {
    align-self: flex-start;
    width: 100%;

    .tile-name {
      color: #6e6e82;
      font-size: 20px;
      font-family: $font-family-medium;
      margin-top: 14px;
      margin-bottom: 14px;
    }

    .tile-description {
      color: #6e6e82;
      font-size: 13px;
      font-family: $font-family-book;
      margin-right: 12px;
    }
  }

  .file-size {
    font-size: 16px;
    padding: 0 3px;
    color: #6e6e82;
    margin-left: -60px;
  }
}
