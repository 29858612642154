.request-item-number {
  flex-grow: 1;
  width: calc(100% - 40px);

  .body-middle {
    display: flex;

    .margin {
      margin-right: 20px;
    }
  }

  select {
    min-width: -webkit-fill-available;
  }

  .form-container {
    display: flex;
    flex-direction: column;
    background:
      transparent linear-gradient(
        180deg,
        #f4f5fa 0%,
        #eeecec 100%
      ) 0% 0% no-repeat padding-box;
    box-shadow: 1px 1px 6px rgb(0 0 0 / 16%);
    border-radius: 25px;
    padding-bottom: 10px;

    .form-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 36px;
      padding-top: 36px;
      margin-bottom: 12px;
    }

    .form-explanation {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      padding: 0 36px;

      p {
        font-family: "Roboto", sans-serif;
        font-size: 15px;
      }
    }

    .form-body {
      margin: 0 36px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      ::-webkit-input-placeholder {
        color: lightgrey;
      }

      &.admin {
        margin-top: 20px;
        margin-left: 0;
      }

      &.show {
        display: block;
      }

      &.hide {
        display: none;
      }

      .form-dynamic {
        &.show {
          display: block;
        }

        &.hide {
          display: none;
        }
      }

      .label-row {
        display: flex;
        flex-wrap: wrap;
        flex: auto;

        &.show {
          display: flex;
          flex-wrap: wrap;
          flex: auto;
        }

        &.hide {
          display: none;
        }

        textarea::placeholder {
          color: lightgrey;
        }

        &.details {
          margin-top: 20px;
        }

        &.comments {
          margin-top: 50px;
        }
      }

      .file-upload-wrapper {
        font-size: 1.25em;
        font-weight: 700;
        color: white;
        background-color: white;
        border-radius: 15px;
        display: flex;
        align-items: center;
        cursor: pointer;
        border: 1px dashed #707070;
        margin-top: 0;
        height: 120px;
        width: 100%;
      }
    }

    .form-buttons {
      display: flex;
      align-items: flex-start;
      padding-top: 15px;
      padding-left: 0;

      .action {
        width: 160px;
        height: 35px;
        font-weight: normal;
        font-size: 16px;
        border-radius: 17px;
        border: 1px solid #bbb;
        font-family: "Roboto", sans-serif;
        transition-duration: 0.3s;
        color: white;
        background-color: #02a862;
        box-shadow: 0 3px 6px #00000029;
        cursor: pointer;

        &.clear {
          background-color: #d3372a;
        }
      }
    }

    .new-comments {
      &.show {
        display: block;
      }

      &.hide {
        display: none;
      }
    }
  }

  .toggle-container {
    .slider.round {
      border-radius: 34px;
    }

    .slider.round::before {
      border-radius: 50%;
    }

    .switch {
      position: relative;
      display: inline-block;
      width: 50px;
      height: 25px;
    }

    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    .slider::before {
      position: absolute;
      content: "";
      height: 17px;
      width: 15px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    input:checked + .slider {
      background-color: #02a862;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #2196f3;
    }

    input:checked + .slider::before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }

    .option-text {
      font-size: 1em;
      margin-left: 30px;
      color: grey;
    }
  }
}
