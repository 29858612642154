footer.connect-footer {
  height: auto;
  background-color: white;
  transition-duration: 0.2s;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;

  .connect-footer-container {
    width: 100%;
    z-index: 1;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin-top: -200px;
  }

  .connect-footer-logo {
    margin-top: 12px;
    display: flex;
    align-items: center;

    .starbucks-main-logo {
      max-height: 60px;
    }

    hr {
      width: 100%;
    }
  }

  .links {
    margin-top: 12px;
    display: flex;
    justify-content: center;

    @media only screen and (max-width: 560px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .link {
    width: 175px;

    @media only screen and (max-width: 560px) {
      padding-top: 12px;
    }
  }

  a.link {
    font-weight: bold;
    font-family: "Roboto", serif;
    border: none;
    box-shadow: none;
    color: #707070;
    background: none;
    text-decoration: none;
    padding: 1px 20px;
    cursor: pointer;
    font-size: 13.3333px;
    text-align: center;

    @media only screen and (max-width: 560px) {
      padding-top: 12px;
    }
  }

  .sbux-copyright {
    font-family: "Roboto", serif;
    color: #6e6e82;
    font-size: 13px;
    text-align: center;
    margin-bottom: 15px;
    margin-top: 10px;
    padding: 10px;
  }

  .copyright {
    font-family: "Roboto", serif;
    color: #6e6e82;
    font-size: 13px;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    margin-top: 10px;
    margin-left: 10px;
    cursor: pointer;
    justify-content: center;
  }

  .orderly-logo {
    height: 16px;
    margin-left: 4px;
  }
}
