.table-detail {
  margin-bottom: 20px;

  &.full-screen {
    position: absolute;
    z-index: 2;
    padding: 10px;
    background: transparent linear-gradient(180deg, #f4f5fa 0%, #eeecec 100%) 0% 0% no-repeat padding-box;
    border-radius: 25px;
    bottom: 10px;
    left: 10px;
    margin: 0;

    &.collapsed {
      .pagination,
      table,
      .edit-buttons {
        display: none;
      }

      .table-detail-header {
        margin-bottom: 0;
      }
    }
  }

  button.closed,
  .expanded {
    width: 30px;
    height: 30px;
    background-color: #02a862;
    color: white;
    font-weight: bold;
    border-radius: 20px;
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    border: none;
    margin: 5px;
    box-shadow: lightgrey 0 0 10px;
    transition-duration: 0.3s;
    cursor: pointer;
  }

  .closed {
    transform: rotate(45deg);
  }

  .table-detail-header,
  .table-detail-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    .pagination,
    input.search {
    }

    .search-container {
      position: relative;

      &::before {
        content: url("/assets/icons/Search 20.svg");
        background-position: center;
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        top: 20px;
        bottom: 0;
        left: 10px;
        position: absolute;
      }
    }

    input.search {
      height: 30px;
      width: 100%;
      padding-left: 40px;
      color: white;
      background-color: $colour_grayscale_darkest;
      border: none;

      &::placeholder {
        color: white;
      }
    }

    h2 {
      margin-block-end: 20px;
      margin-block-start: 20px;
      color: #4d4f5c;
      font-size: 22px;
      font-family: "Roboto", sans-serif;
    }
  }

  table {
    .flexed-column {
      display: flex;
      align-items: end;
      flex-wrap: wrap;

      .linked-site {
        background: #02a862;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-left: 10px;
        margin-top: 9px;
      }

      thead tr th {
        height: 20px;

        &:first-child {
          width: 25px;
        }
      }

      td {
        &:first-child,
        &:last-child {
          width: 25px;
          height: 36px;
        }
      }
    }
  }

  tbody tr {
    &.active {
      background-color: #4bd991;
    }

    button {
      width: 112px;
      height: 28px;
      margin: 6px;
      border-radius: 30px;
      font-size: 16px;
      border: none;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
      font-family: $font-family-medium;
      color: white;
      background-color: $colour_SBgreen_light;
      cursor: pointer;
      transition-duration: 0.3s;

      &:hover,
      &:active,
      &:focus {
        transition-duration: 0.3s;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
      }

      &.bad {
        background-color: #d3372a;
      }

      &.multi-select {
        height: 24px;
        width: 24px;
        border-radius: 8px;
        background-color: $colour_SBgreen_dark;
      }
    }
  }
}
