.template {
  margin-left: -75px;
  padding-left: 100px;
  overflow-x: hidden;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: black;
  background-color: white;
  margin-bottom: 8px;
  box-shadow: -1px 10px 9px -8px rgb(0 0 0 / 30%);

  @media only screen and (max-width: 1200px) {
    flex-direction: column;
    padding-left: 0;
    margin-left: 0;
  }

  .template-row {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    max-width: 1200px;

    @media only screen and (max-width: 1000px) {
      flex-direction: column;
    }
  }

  .template-stack {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: #707070;
  }

  h2 {
    font-weight: bold;
    margin-block-end: 20px;
  }

  .heading-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .content-label {
    background: none;
    color: $colour_SBgreen_light;
    padding: 4px 12px;
    border: 1px solid;
    border-color: $colour_SBgreen_light;
    background-color: #f7f7f7;
    border-radius: 4px;
    margin: 0 24px;
  }

  .content-expander {
    display: flex;

    .expand-button {
      border: none;
      padding: 4px 12px;
      font-size: 13px;
      font-weight: normal;
      font-family: Arial;
      color: #000;
      margin-right: 5px;
      border-radius: 4px;
      background-color: #f7f7f7;
      cursor: pointer;
      display: flex;

      &.audience {
        cursor: default;
      }

      .target-icon {
        background-image: url(/assets/map-icons/target.svg);
        height: 14px;
        width: 14px;
        margin-right: 5px;
      }

      .download-icon {
        background-image: url(/assets/icons/Download-black.svg);
        height: 14px;
        width: 14px;
        margin-right: 5px;
      }
    }
  }

  .image {
    max-width: 400px;
    overflow: hidden;
    padding: 24px;
    display: flex;

    img,
    video,
    audio {
      width: 100%;
      border-radius: 25px;
    }
  }
}

.MuiTooltip-popper {
  ul {
    padding-inline-start: 15px;
  }
}
