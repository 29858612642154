.shipping-details {
  .header-shipping {
    margin-top: 30px;
    margin-bottom: 35px;
  }

  .header-buttons {
    margin-right: 30px;
  }

  .form-container {
    margin-top: 20px;
  }

  .action {
    &.view-additional {
      height: 45px;
      width: 250px;
    }
  }

  .comments {
    &.split {
      margin-left: 30px;
    }
  }
}
