.bulk-edit-body {
  overflow: auto;
  max-height: 400px;

  .reminder-wrapper {
    display: flex;
    margin-bottom: -20px;

    .reminder-title {
      text-align: left;
      margin-top: 38px;
      margin-bottom: 5px;
      min-width: max-content;
      margin-right: 10px;

      b {
        margin-right: 3px;
      }
    }

    .reminder-label {
      margin-top: 30px;
      margin-bottom: 5px;
    }
  }

  .small-input {
    width: 150px !important;
  }
}
