.template.template-4 {
  color: $colour_grayscale_black;
  background-color: $colour_grayscale_dark;

  .template-row {
    flex-direction: row-reverse;

    @media only screen and (max-width: 1000px) {
      flex-direction: column-reverse;
    }
  }

  .image {
    @media only screen and (max-width: 1000px) {
      max-height: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }

    @media only screen and (max-width: 1000px) {
      max-height: 400px;
      width: 100%;
      max-width: unset;
    }
  }

  .image img {
    border-radius: unset;
  }

  .heading-row {
    align-self: flex-end;
    margin-bottom: 10px;
  }

  .content-label {
    margin: unset;
  }

  .col-1,
  .col-3 {
    flex-grow: 0;
    flex-shrink: 0;
    padding: unset;

    @media only screen and (max-width: 1000px) {
      width: 100%;
    }
  }

  .col-2 {
    flex-grow: 1;
    flex-shrink: 1;
    margin: 18px;
  }

  h2.heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $colour_grayscale_black;
    text-align: center;
    font-size: 50px;
    line-height: 60px;
    font-weight: 400;
    text-transform: uppercase;
    margin-left: 48px;
  }

  h3.sub-heading {
    color: $colour_grayscale_black;
    font-weight: 400;
    margin-bottom: unset;
  }

  .content {
    color: $colour_grayscale_black;
    transition-duration: 0.3s;
    background-color: #fffc;
    border-radius: 5px;
    align-self: stretch;
    padding-left: 10px;
    padding-right: 10px;
    max-height: 550px;
    overflow: auto;

    .content-content {
      height: 0;
      overflow: hidden;
    }

    &.content-expanded {
      max-height: 550px;
      overflow: auto;

      .content-content {
        height: 100%;
        overflow: auto;
      }
    }

    p {
      color: unset;
    }

    a {
      padding: 0;
      color: unset;
      text-decoration: underline;
      font-weight: 500;
    }
  }

  .content-expander {
    margin-top: 15px;
  }
}
