@import '../../../style/variables.scss';

#bookingRequestForm.print-mode {
  .floating-panel,
  .tab-container {
    display: none;
  }

  .form-container {
    width: 1000px;
    margin-bottom: 45px;
    display: block !important;
  }
}

.form-body {
  .sync-button {
    border: none;
    cursor: pointer;
    background: #f4f5fa;
    width: 50px;
    vertical-align: middle;

    img {
      filter: invert(0.5) !important;
      height: 30px;
    }
  }

  .uneditable {
    border: none;
  }

  &.document {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;

    .form-buttons {
      padding-left: 0 !important;
      display: flex;
      flex-direction: row;

      .action {
        &.download {
          width: 50px;
          border-radius: 50%;
          cursor: pointer;

          div {
            background-image: url('/assets/icons/Download light.svg');
            width: 25px;
            margin-left: 5px;
            height: 30px;
          }
        }

        &.negative {
          &.delete {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            cursor: pointer;
            position: absolute;
            right: 10px;
            top: 10px;
          }
        }
      }
    }

    .documents-wrapper {
      margin: 0 0;
      margin-bottom: 28px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: flex-end;

      .document-tile-header {
        font-weight: bold;
        text-align: center;
        margin-bottom: 10px;
      }

      .file-title {
        max-height: 40px;
        overflow-wrap: break-word;
        overflow: hidden;
        text-align: center;
      }
    }

    .document-details {
      margin-top: 40px;
      margin-right: 18px;
      background-color: white;
      padding: 50px;
      border-radius: 10px;
      box-shadow: 1px 1px 6px rgb(0 0 0 / 16%);
      position: relative;
      width: 250px;
      height: 250px;
    }
  }
}
