.form-container.audit-history{
  .form-body{
    display:block;

    .section-header{
          display: flex;
            justify-content: space-between;
            font-weight: bolder;
            font-size: 17px;
            border-bottom: 1.5px solid grey;
    }
  }
}