.message-row {
  background-color: $colour_grayscale_white;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: middle;

  &.not-acknowledged {
    border: 2px solid $colour_bad;
  }

  .message-header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-right: 10px;
    flex-grow: 1;

    .message-headings {
      display: flex;
      justify-content: space-between;
      margin-bottom: 6px;

      h5,
      h6 {
        margin-block-start: 0;
        margin-block-end: 0;
      }

      h6 {
        color: $colour_grayscale_dark;
        font-size: 14px;
      }
    }
  }

  .message-body {
    font-size: 12px;
    display: flex;
    flex-direction: column;

    button.action {
      background-color: $colour-highlight;
      color: $colour_grayscale_white;
      margin-top: 10px;
    }

    h6 {
      margin-block-start: 0;
      margin-block-end: 0;
      text-align: right;
      color: $colour_grayscale_dark;
      font-size: 14px;
    }
  }
}
