.icon {
  width: 90px;
  flex-shrink: 0;
  background-repeat: no-repeat;
  background-position: center;

  &.icon-globe {
    background-image: url("/assets/siteid-icons/globe.svg");
    height: 78px;
    width: 78px;
    margin-left: 7px;
    margin-right: 7px;
  }

  &.icon-pin {
    background-image: url("/assets/siteid-icons/Pin.svg");
    height: 27px;
    width: 21px;
    margin-left: 6px !important;
    margin-right: 0;
    background-size: contain;
  }

  &.icon-globe-pin {
    background-image: url("/assets/siteid-icons/Globe and Pin.svg");
    height: 78px;
    width: 78px;
    margin-left: 7px;
    margin-right: 7px;
  }

  &.icon-siteid-edit {
    background-image: url("/assets/siteid-icons/Edit.svg");
    height: 29px;
    width: 20px;
    margin-left: 0;
    margin-right: 0;
    background-size: contain;
  }

  &.icon-siteid-search {
    background-image: url("/assets/siteid-icons/Search.svg");
    height: 20px;
    width: 20px;
    margin-bottom: 2px;
    margin-left: 6px !important;
    margin-right: 0;
    background-size: contain;
  }

  &.icon-siteid-file {
    background-image: url("/assets/siteid-icons/File.svg");
    height: 78px;
    width: 78px;
    margin-left: 7px;
    margin-right: 7px;
  }

  &.icon-siteid-filter {
    background-image: url("/assets/siteid-icons/Filter.svg");
    height: 78px;
    width: 78px;
    margin-left: 7px;
    margin-right: 7px;
  }

  &.icon-siteid-expiring {
    background-image: url("/assets/siteid-icons/History.svg");
    height: 24px;
    width: 28px;
    margin-left: 6px;
    margin-right: 6px;
    background-size: contain;
    fill: #ff7e90;
    stroke: #ff7e90;
  }

  &.icon-siteid-expiring-white {
    background-image: url("/assets/siteid-icons/History_white.svg");
    height: 24px;
    width: 28px;
    margin-left: 6px;
    margin-right: 6px;
    background-size: contain;
    fill: white;
    stroke: white;
  }

  &.icon-siteid-expiring-green {
    background-image: url("/assets/siteid-icons/History_green.svg");
    height: 24px;
    width: 28px;
    margin-left: 6px;
    margin-right: 6px;
    background-size: contain;
    fill: #0ed988;
    stroke: #0ed988;
  }

  &.icon-siteid-flag {
    background-image: url("/assets/siteid-icons/Flag.svg");
    height: 24px;
    width: 28px;
    margin-left: 6px;
    margin-right: 6px;
    background-size: contain;
  }

  &.icon-siteid-flag-white {
    background-image: url("/assets/siteid-icons/Flag_white.svg");
    height: 24px;
    width: 28px;
    margin-left: 6px;
    margin-right: 6px;
    background-size: contain;
  }

  &.icon-full-screen {
    background-image: url("/assets/map-icons/fullscreen.svg");
    height: 24px;
    width: 28px;
    margin-left: 6px;
    margin-right: 6px;
    background-size: contain;
  }

  &.icon-siteid-access-white {
    background-image: url("/assets/whatissiteid/icons/Access.svg");
    height: 74px;
    width: 64px;
    margin-left: 14px;
    margin-right: 14px;
    background-size: contain;
  }

  &.icon-siteid-features-white {
    background-image: url("/assets/whatissiteid/icons/Features.svg");
    height: 74px;
    width: 64px;
    margin-left: 14px;
    margin-right: 14px;
    background-size: contain;
  }

  &.icon-siteid-pin-white {
    background-image: url("/assets/whatissiteid/icons/Pin.svg");
    height: 74px;
    width: 64px;
    margin-left: 14px;
    margin-right: 14px;
    background-size: contain;
  }

  &.icon-siteid-reports-white {
    background-image: url("/assets/whatissiteid/icons/Reports.svg");
    height: 74px;
    width: 64px;
    margin-left: 14px;
    margin-right: 14px;
    background-size: contain;
  }
}
