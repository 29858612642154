.emergency-filter-container {
  display: flex;
  margin-bottom: 20px;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-between;
  margin-left: 3%;
}

.emergency-order-chart-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.large-width-container {
  width: 60% !important;
}

.small-width-container {
  width: 40% !important;
}

.emergency-dashboard {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.emergency-table-container {
  overflow: auto !important;
}

.emergency-table-container .table-style {
  width: 100% !important;
  table-layout: auto !important;
}

.emergency-table-container .table-style td {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}
