.notification-card {
  margin-bottom: 10px;
  margin-left: 24px;
  margin-top: 48px;
  display: flex;
  border-left: 1px solid #e9e9f0;

  .sidebar-notif {
    cursor: pointer;
  }

  &:last-child {
    border: none;
  }

  .icon-holder {
    height: 38px;
    width: 38px;
    border: 1px solid #ffca83;
    margin-right: 10px;
    margin-left: -20px;
    flex-shrink: 0;
    margin-top: -45px;
    border-radius: 50%;
    position: relative;

    &.admin {
      border: 1px solid #ff7285;
    }

    &.order-management {
      border: 1px solid #4bd991;
    }

    &.item-catalogue {
      border: 1px solid #3d8b99;
    }

    &.document-library {
      border: 1px solid #4b71d9;
    }

    &.quality-awareness {
      border: 1px solid #d9c44b;
    }

    &.pricing-catalogue {
      border: 1px solid #4bd991;
    }

    &.forecasting {
      border: 1px solid #4bd99e;
    }

    &.orderly-inventory {
      border: 1px solid #61c3df;
    }

    &.reports {
      border: 1px solid #26c066;
    }

    &.policies {
      border: 1px solid #bb61df;
    }

    .icon {
      height: 26px;
      width: 26px;
      position: relative;
      margin-right: 6px;
      margin-left: 6px;
      margin-top: 5px;
    }
  }

  .notification {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #4d4f5c;
    font-size: 13px;
    margin-top: -46px;
    width: 100%;
    margin-bottom: 20px;
    text-align: left;

    h5.title {
      margin-block-start: 0;
      margin-block-end: 0;
      font-size: 13px;
      color: #4d4f5c;
      font-family: $font-family-medium;
    }

    .message {
      font-family: $font-family-book;
    }

    .date {
      opacity: 0.5;
      font-family: $font-family-light;
    }

    button {
      background-color: #ffca83;
      box-shadow: none;
      color: white;
      font-weight: normal;
      flex-shrink: 0;
      margin-right: 20px;

      &.action {
        background-color: #02a862;
      }
    }
  }
}
