.multi-select-siteid {
  background-color: rgb(238, 236, 236);
  color: white;
  width: 300px;
  max-height: 300px;
  border-radius: 20px;
  padding: 12px;
  box-shadow: rgb(197, 196, 196) 0 0 25px;
  margin-top: 15px;
  margin-bottom: 10px;

  .container-multi {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .icon {
      width: 25px;
      height: 25px;
      background-size: contain;
    }

    .multi-title {
      text-align: center;
      font-size: 1em;
      height: 20px;
      margin-bottom: 0;
    }

    button.closed,
    .expanded {
      width: 30px;
      height: 30px;
      background-color: #02a862;
      color: white;
      font-weight: bold;
      border-radius: 20px;
      font-family: "Roboto", sans-serif;
      font-size: 20px;
      border: none;
      margin: 5px;
      box-shadow: lightgrey 0 0 10px;
      transition-duration: 0.3s;
      cursor: pointer;
    }

    .closed {
      transform: rotate(45deg);
    }
  }

  .search-container {
    display: flex;
    margin-top: 15px;
  }

  .scroll-container {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 120px;
    margin-bottom: 7px;
  }

  .toggle-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  .selector {
    .search {
      width: 250px;
      margin-left: 10px;
    }

    .button.action.square {
      background-image: url(/assets/siteid-icons/Search.svg);
      background-repeat: no-repeat;
      background-position: 50%, 0;
      margin-top: 0;
      margin-left: 15px;
    }

    .dropdowns {
      width: 230px;
    }

    .select-buttons {
      display: flex;
    }

    .button-two,
    .button-three {
      width: 108px;
      height: 30px;
      background-color: #02a862;
      color: white;
      font-weight: bold;
      border-radius: 20px;
      border: none;
      margin: 15px;
      box-shadow: lightgrey 0 0 10px;
    }

    .button-two,
    .button-three:hover {
      cursor: pointer;
    }

    .button-three {
      background-color: #d3372a;
    }

    .checkboxes {
      display: inline-block;
    }

    .slider.round {
      border-radius: 34px;
    }

    .slider.round::before {
      border-radius: 50%;
    }

    .switch {
      position: relative;
      display: inline-block;
      width: 50px;
      height: 25px;
    }

    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    .slider::before {
      position: absolute;
      content: "";
      height: 17px;
      width: 15px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    input:checked + .slider {
      background-color: #02a862;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #2196f3;
    }

    input:checked + .slider::before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }

    .option-text {
      font-size: 1em;
      margin-left: 30px;
      color: grey;
    }
  }

  .hideSelector {
    display: none;
  }
}
