@import '../../../style/variables.scss';

.customer-ordertracker-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  &.completed {
    .summary-container {
      .left-container,
      .completed-hidden {
        display: none;
      }
    }
  }

  &.in-progress {
    .archive-filter,
    .inprogress-hidden {
      display: none;
    }
  }

  .accordion .accordion-header {
    cursor: default;
  }
}

.summary-container {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .order-progress-fields {
    display: flex;
    flex-direction: column;
  }

  .archive-panel {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .left-container {
    margin-right: 20px;
  }

  .right-container {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .floating-panel {
      min-width: 550px;
      width: calc(50% - 20px);

      &:first-child {
        margin-right: 20px;
      }

      &:last-child {
        width: 100%;
      }
    }

    .submit-container {
      display: flex;
      flex-direction: row;

      label {
        min-width: 30vw;

        textarea {
          width: 100%;
        }
      }

      .flex-bottom {
        align-self: flex-end;
        margin-bottom: 5px;
        margin-left: 20px;
      }

      .submit-success {
        margin-bottom: 12.5px;
      }
    }

    .order-status-fields {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      label,
      input {
        width: 400px;
      }
    }
  }
}

.table-container {
  .export {
    display: flex;
    justify-content: center;

    @media only screen and (max-width: 560px) {
      margin-bottom: 15px;
      margin-left: 0;
    }

    .icon {
      width: 25px;
      height: 25px;
      margin-top: 3px;
    }
  }

  .order-status {
    border-radius: 5px;
    text-align: center;
    width: 150px;
    padding: 4px 8px;
    margin: 0 auto;
  }

  .order-status-1,
  .order-status-2 {
    background-color: $colour_bad;
  }

  .order-status-3 {
    background-color: orange;
  }

  .order-status-4,
  .order-status-5 {
    background-color: $colour_good;
  }
}

.search-order-container {
  display: flex;
  justify-content: space-around;
}
