.template.template-8 {
  color: $colour_grayscale_black;

  .template-row {
    position: relative;

    @media only screen and (max-width: 1000px) {
      flex-direction: column-reverse;
    }
  }

  .col-1,
  .col-3 {
    flex-grow: 0;
    flex-shrink: 0;
  }

  #box {
    position: absolute;
    top: 0;
    right: -72px;
    align-self: flex-start;
    height: 242px;
    width: 555px;
    background-color: #02a863;
    z-index: 0;
    transform: skew(30deg);
    box-shadow: 0 2px 8px 1px #3c3c3c87;
  }

  .col-3 {
    width: 388px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    align-self: flex-start;
    margin-top: 12px;

    @media only screen and (max-width: 1000px) {
      width: 100%;
      justify-content: flex-end;
      padding-right: 110px;
    }

    .image {
      height: 200px;
      width: 200px;
      padding: 0%;
      margin: 10px;
      border-radius: 50%;
      z-index: 1;
      background-color: black;

      img {
        margin-bottom: 10px;
        z-index: 1;
        align-self: center;
        border-radius: unset;
      }
    }
  }

  .col-2 {
    flex-grow: 1;
    flex-shrink: 1;
    margin: 24px;
    margin-right: 100px;

    @media only screen and (max-width: 1000px) {
      margin-right: 24px;
    }

    .content-label {
      margin: 0;
      margin-right: 60px;

      @media only screen and (max-width: 1000px) {
        margin-right: 0;
      }
    }
  }

  h2.heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $colour_grayscale_black;
    text-align: center;
    font-size: 50px;
    line-height: 60px;
    font-weight: 400;
    text-transform: uppercase;
    margin-left: 48px;
  }

  h3.sub-heading {
    color: $colour_grayscale_black;
    font-weight: 400;
    margin: 0;
    margin-right: 60px;
  }

  .content {
    color: $colour_grayscale_black;
    transition-duration: 0.3s;
    max-height: 550px;
    overflow: auto;

    .content-content {
      height: 0;
      overflow: hidden;
    }

    &.content-expanded {
      max-height: 550px;
      overflow: auto;

      .content-content {
        height: 100%;
        overflow: auto;
      }
    }

    p {
      color: unset;
    }

    a {
      padding: 0;
      color: unset;
      text-decoration: underline;
      font-weight: 500;
    }
  }

  .content-expander {
    margin-top: 15px;
  }
}
