footer.page-footer {
  height: auto;
  background-color: white;
  transition-duration: 0.2s;
  flex-shrink: 0;
  padding-left: 100px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @media only screen and (max-width: 560px) {
    padding-left: 20px;
  }

  .section-title {
    font-size: 16px;
    color: #0b4b4b;
    font-family: $font-family-medium;
    height: 25px;
    margin-bottom: 18px;
    margin-top: 29px;
  }

  .links {
    display: flex;
    align-items: flex-start;

    @media only screen and (max-width: 560px) {
      flex-direction: column;
    }

    .link-section {
      font-family: $font-family-book;
      font-size: 13px;
      display: flex;
      width: 196px;
      flex-direction: column;
      align-items: flex-start;

      button {
        padding: 0;
        margin-bottom: 15px;
      }
    }
  }

  .copyright {
    font-family: $font-family-book;
    color: #6e6e82;
    font-size: 13px;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    margin-top: 10px;
    cursor: pointer;

    .orderly-logo {
      height: 16px;
      margin-left: 4px;
    }

    @media only screen and (max-width: 560px) {
      margin-top: 20px;
    }
  }

  .starbucks-text-logo {
    margin-right: 20px;
    margin-bottom: 30px;
  }
}
