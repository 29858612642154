//Top level structure
.app {
  display: flex;
  flex-direction: row;

  .app-body {
    display: flex;
    flex-grow: 1;
    overflow: hidden;

    .app-left {
      z-index: 0;

      @media only screen and (max-width: 1560px) {
        z-index: 20;
      }
    }

    .app-right {
      flex-grow: 1;
      overflow: auto;

      .app-content {
        min-height: calc(100vh - 435px);
        justify-content: center;
        padding-left: 44px;
        padding-right: 44px;
        display: flex;
        padding-top: 40px;
        margin-bottom: 40px;

        @media only screen and (max-width: 1560px) {
          padding-left: 22px;
          padding-right: 22px;
        }

        @media only screen and (max-width: 1240px) {
          padding-left: 10px;
          padding-right: 10px;
        }

        /* .page { this was the devil
          //flex-grow: 1;
        } */
      }
    }
  }
}

.app-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(12, 12, 12, 0.14);
  position: fixed;
  z-index: 999;
}

.spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid $colour_SBgreen_dark;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
