.csr-dashboard-page {
  .csr-container {
    .table-body {
      th {
        cursor: pointer;
        white-space: nowrap;
        min-width: max-content;

        .flexed-header {
          display: flex;

          .expand {
            height: fit-content;
            width: fit-content;
            margin-left: 5px;

            &.asc {
              rotate: 90deg;
            }

            &.desc {
              rotate: 271deg;
            }
          }
        }
      }
    }
  }

  .map-wrapper {
    width: 70%;
    padding: 5px 0 20px 0;
    position: relative;

    #google-map-csr {
      height: 100%;
      width: 100%;
      border-radius: 10px;
      padding: 10px;
      z-index: 1;
    }

    .location-button-group {
      display: flex;
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 2;
      box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.16);
      background-color: white;

      button {
        cursor: pointer;
        border: none;
        width: 100px;
        height: 30px;
        outline: none;

        &.active {
          background-color: #dedede;
          font-weight: bold;
        }
      }
    }

    .action {
      &.negative {
        position: absolute;
        bottom: 46px;
        right: 210px;
        z-index: 2;
      }
    }
  }

  .csr-summary {
    input {
      width: 150px;
      margin-bottom: 0;
    }

    label {
      width: 200px;
    }

    h3 {
      margin-bottom: 5px;
    }

    .csr-filters {
      display: flex;
    }
  }

  .filter-map-row {
    display: flex;
    justify-content: space-between;

    .floating-panel {
      flex-grow: 1;

      .csr-filters {
        display: flex;
        flex-wrap: wrap;
      }

      .btn-group {
        display: flex;
        margin-top: 15px;
      }

      select,
      input {
        margin-bottom: 0;
      }

      label {
        margin-left: 10px;
      }

      &:nth-child(2) {
        margin-left: 25px;
      }
    }
  }

  .csr-filter-panel-header {
    display: flex;
    justify-content: space-between;

    .filter-btns {
      display: flex;

      button {
        margin-right: 20px;
      }
    }

    h3 {
      margin-bottom: 0;
    }

    .icon {
      cursor: pointer;
      align-self: center;
      padding: 14px;
      border-radius: 50%;
      background-color: #ebeaea;
      color: #c3c3c3;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1607843137);
    }
  }

  .csr-filters {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;

    label {
      margin: 0 15px 0 15px;
    }
  }

  .csr-summary-dashboard {
    width: fit-content;

    .summary-line {
      display: flex;
      justify-content: space-between;
      text-align: center;

      .dash-item {
        background: transparent linear-gradient(180deg, #f4f5fa 0%, #eeecec 100%) 0% 0% no-repeat padding-box;
        box-shadow: 1px 1px 6px rgb(0 0 0 / 16%);
        border-radius: 10px;
        padding: 36px;
        margin-bottom: 20px;
        height: 140px;
        width: 300px;
        padding-top: 55px;

        h2 {
          font-size: 28px;
          font-weight: bold;
          color: #707070;
        }
      }
    }
  }
}
