body {
  margin: 0;
  font-family: $font-family-medium;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e4e4e4;
  height: 100vh;

  @media (max-width: 940px) {
    overflow-x: hidden;
  }
}

h1 {
  font-family: $font-family-medium;
  font-weight: bold;
  font-size: 40px;
  line-height: 40px;
  color: $colour_grayscale_darkest;
  margin-block-start: 0;
  margin-block-end: 20px;
}

h2 {
  font-family: $font-family-medium;
  font-size: 30px;
  line-height: 30px;
  color: $colour_grayscale_dark;
  margin-block-start: 0;
  margin-block-end: 10px;
  font-weight: normal;

  &.darker {
    color: $colour_grayscale_darkest;
  }
}

h3 {
  margin-block-start: 0;
  font-family: $font-family-medium;
  font-weight: bold;
  font-size: 24px;
  color: $colour_grayscale_darkest;
}

h4 {
  font-family: $font-family-medium;
  font-weight: bold;
  font-size: 20px;
  color: $colour_grayscale_darkest;
  margin-bottom: 0;
}

h5 {
  font-family: $font-family-medium;
  font-weight: bold;
  font-size: 16px;
  color: $colour_grayscale_darkest;
  margin-bottom: 0;
}

p {
  font-family: $font-family-book;
  font-weight: 200;
  font-size: 15px;
  color: $colour_grayscale_darkest;
  line-height: 20px;
}
