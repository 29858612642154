.edit-feedback-page {
  .form-container {
    .star-rating {
      display: flex;
      justify-content: space-evenly;
      margin-right: 0;
      margin-top: 15px;

      label {
        display: flex;
        flex-direction: column-reverse;
        line-height: 0;
        align-items: center;
        width: 20%;
        margin-right: auto !important;
        cursor: pointer;

        input[type="radio"] {
          margin-right: 8px;
          margin-left: 8px;
          display: none;
        }
      }
    }

    .form-body > div {
      margin-right: 80px;
    }

    .form-body > div > label {
      margin-bottom: 20px;
    }

    .inputfile {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }

    .file-upload-wrapper {
      font-size: 1.25em;
      font-weight: 700;
      color: white;
      background-color: white;
      border-radius: 15px;
      display: flex;
      align-items: center;
      cursor: pointer;
      border: 1px dashed #707070;
      margin-top: 18px;
      height: 120px;
      padding-right: 10px;

      p {
        font-family: $font-family-book;
        font-size: 15px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .icon.icon-image-upload {
        margin-right: 15px;
      }
    }
  }
}

.form-label-button-wrap {
  display: flex;
  flex-flow: column;
  margin-bottom: 20px;
}
