@import '../../../style/variables';

.edit-order-page {
  .floating-header {
    margin-bottom: 0;
  }

  .accordion {
    .accordion-header {
      cursor: default;
    }
  }

  .edit-order-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .group-details {
      width: calc(50% - 10px);

      @media screen and (max-width: 991px) {
        width: 100%;
      }
    }
  }

  .horizontal-flex.progress-stages {
    flex-wrap: wrap;

    .progress-stage {
      flex-basis: 230px;

      label,
      input {
        width: 100%;
      }
    }
  }

  .submitted {
    :invalid,
    :invalid:focus {
      border: 2px solid $colour_bad;
      box-shadow: none;
    }
  }

  .button-container {
    display: flex;

    :first-child {
      margin-right: 10px;
    }
  }

  .submit-result {
    line-height: 5px;
    margin-left: 15px;
    font-weight: 300;
  }

  .progress-state-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .progress-state {
    .progress-update {
      padding: 8px 5px;
    }
  }

  .comments-container,
  .comments {
    width: 100%;

    textarea {
      width: 100%;
      display: block;
    }
  }
}
