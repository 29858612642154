.messages-page {
  transition: 0.5s;
  background-color: white;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);

  .messages-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    .logo-stack {
      display: flex;
      justify-content: flex-start;
      margin-top: 36px;
      margin-left: 36px;

      h2 {
        margin-block-end: 20px;
        margin-block-start: 20px;
        color: #6e6e82;
        font-size: 22px;
        font-family: $font-family-medium;
      }
    }

    .close-button {
      font-size: 36px;
      margin-right: 24px;
      margin-top: 24px;
      cursor: pointer;
      color: $colour-grayscale-dark;
    }
  }

  .messages-list {
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;

    .connector-holder {
      width: 73vw;
      margin-left: 24px;

      @media only screen and (max-width: 1020px) {
        width: 90vw;
      }

      @media only screen and (max-width: 600px) {
        display: none;
      }

      .connector {
        border-right: 1px solid #e9e9f0;
        height: 38px;
        width: 320px;
        margin-top: -22px;
        margin-bottom: -12px;
      }
    }

    .date-pointer {
      opacity: 0.6;
      font-family: $font-family-light;
      font-size: 15px;
      margin-bottom: 6px;
      align-self: flex-start;
      margin-left: 220px;
      border-bottom: 1px solid #e9e9f0;
      width: 80vw;

      @media only screen and (max-width: 1020px) {
        width: 100vw;
        margin-left: 20px;
      }
    }
  }

  .mark-all-container {
    display: flex;
    margin-right: 36px;

    button {
      background-color: #ffca83;
      box-shadow: none;
      color: white;
      font-weight: normal;
      flex-shrink: 0;
    }
  }

  .welcome-all {
    margin-top: 38px;
    margin-left: 44px;

    h2 {
      font-size: 24px;
      margin-bottom: 10px;
      color: #4d4f5c;
    }

    h1 {
      margin-top: 10px;
    }
  }

  .notification-filter-container {
    align-self: center;
    width: 70vw;

    .notification-filter-dropdowns {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-end;

      label {
        margin-right: 5px;

        select {
          background-color: #f4f2f2;
        }
      }

      .filter-search-buttons {
        margin-bottom: 19px;
      }
    }
  }

  .messages-pagination {
    display: flex;
    justify-content: center;
    margin-top: 6px;
    margin-bottom: 32px;
  }
}
