
.key-wrapper {
  display: flex;
  justify-content: flex-start;
  margin: 8px;
  margin-top: 0;
  max-width: 550px;
  background: transparent linear-gradient(180deg, #f4f5fa 0%, #eeecec 100%) 0% 0% no-repeat padding-box;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.16);
  border-radius: 25px;
  position: absolute;
  z-index: 3;
  top: 8px;
  left: 0;
  flex-wrap: wrap;

  .key-header {
    margin: 10px;
    position: absolute;
    left: 10px;
    top: 10px;

    .close-button,
    .open-button {
      height: 24px;
      width: 24px;
      border-radius: 30px;
      font-size: 16px;
      border: none;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
      font-family: "Roboto", sans-serif;
      color: white;
      background-color: #02a862;
      cursor: pointer;
      transition-duration: 0.3s;
      padding: 0;
    }

    .close-button {
      transform: rotate(45deg);
    }
  }

  h2 {
    margin: 14px;
    padding-top: 54px;
    color: #4d4f5c;
    font-size: 22px;
    font-family: "Roboto", sans-serif;
  }

  .markers-lower {
    display: flex;
    margin: 12px;
  }

  .key {
    display: flex;

    .flagName {
      display: flex;
      justify-content: left;
      margin: 12px;
      flex-wrap: wrap;
    }

    .flag,
    img {
      width: 20px;
      justify-content: center;
    }

    #equityTerritories {
      background-color: rgba(255, 0, 0, 0.1);
      height: 20px;
      width: 20px;
      flex-shrink: 0;
      border-style: solid;
      border-width: thin;
    }

    #licenseeTerritories {
      background-color: rgba(50, 157, 168, 0.2);
      height: 20px;
      width: 20px;
      flex-shrink: 0;
      border-style: solid;
      border-width: thin;
    }

    #drive-through {
      background-color: gold;
      height: 20px;
      width: 20px;
      flex-shrink: 0;
      border-style: solid;
      border-width: thin;
      border-radius: 50%;
    }
  }

  .wrapper {
    line-height: unset;
    color: #4d4f5c;
    margin-top: 4px;
    margin-bottom: auto;
    margin-left: 8px;
    width: 200px;
    height: 30px;
    display: flex;
    align-items: center;
  }

  .label {
    margin: 6px;
    margin-top: 4px;
    min-width: 132px;
  }

  .anti-toggle {
    width: 44px;
    border-radius: 30px;
    margin: 4px;
    height: 20px;
    border: 2px solid #ccc;
    flex-shrink: 0;
  }

  .toggle-wrapper {
    width: 44px;
    height: 20px;
    border: none;
    border-radius: 30px;
    position: relative;
    flex-shrink: 0;
    margin: 4px;

    .toggle-icon {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 34px;

      &::before {
        position: absolute;
        content: "";
        height: 13px;
        width: 13px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 50%;
      }
    }

    input:checked + .toggle-icon {
      background-color: #02a862;
    }

    input:focus + .toggle-icon {
      box-shadow: 0 0 1px #02a862;
    }

    input:checked + .toggle-icon::before {
      -webkit-transform: translateX(24px);
      -ms-transform: translateX(24px);
      transform: translateX(24px);
    }

    input:disabled + .toggle-icon {
      opacity: 0.5;
    }

    input[type="checkbox"] {
      position: fixed;
      top: -50px;
      left: -100px;
      opacity: 0;
    }
  }

  .hideKey {
    display: none;
  }

  .detail-markers {
    display: block;
  }
}
