.file-container {
  //Pulled from shared elements

  .file-icon-and-buttons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .file-detail{
      flex-grow:1;
      max-width: calc(100% - 120px);
    }

    .icon.icon-file{
      margin:0 auto; 
    }
  }

  .edit-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

  .file-footer{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    .file-description {
      font-weight: bold;
      font-size: 14px;
      font-family: $font-family-book;
      color: $colour_grayscale_darkest;
      margin: 10px 0;
      padding: 0 3px;
      text-align: center;
      max-width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .file-size{
      font-size: 14px;
      font-weight: normal;
      margin: 10px 0;
      padding: 0 3px;
    }
  }

  

  .document-icon-title {
    flex-grow:1;
      max-width: calc(100% - 120px);
  }
  .file-name {
    font-weight: bold;
    font-size: 14px;
    margin-top:5px;
    font-family: $font-family-medium;
    color: $colour_grayscale_darkest;
    text-align:center;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    button {
      margin-bottom: 5px;
    }
  }
}
