.forecast-change-page {
  .floating-mini-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .remove-button {
      cursor: pointer;
      align-items: center;
      display: flex;
      margin-top: -23px;
      margin-right: 0;
      padding-left: 16px;
      background-color: #d3372a;
      border-radius: 24px;
      color: white;

      .icon.icon-image-remove {
        width: 40px;
        height: 40px;
        margin: 4px;
      }
    }
  }

  .forecast-item-change-body {
    display: flex;
    flex-wrap: wrap;

    & > div {
      margin-right: 10px;

      @media only screen and (max-width: 560px) {
        width: 100%;
        margin-bottom: 15px;
        margin-right: 0;
        margin-right: 20px;
      }

      label,
      input,
      textarea {
        @media only screen and (max-width: 560px) {
          width: 100%;
          margin-bottom: 15px;
          margin-right: 0;
        }
      }
    }
  }

  .forecast-item-change-reason {
    margin-right: 25px;

    label,
    textarea {
      @media only screen and (max-width: 560px) {
        width: 100%;
        margin-bottom: 15px;
        margin-right: 0;
      }
    }
  }

  .form-buttons {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-top: 15px;
    padding-left: 36px;

    @media only screen and (max-width: 560px) {
      width: 100%;
      margin-bottom: 15px;
      margin-right: 0;
      padding-left: 0;
    }

    button {
      width: 330px;
      height: 50px;
      font-size: 20px;
      border-radius: 25px;
      margin-bottom: 20px;
      margin-right: 20px;
      font-family: $font-family-medium;
      font-weight: normal;

      @media only screen and (max-width: 560px) {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}
