.item-pricing-page {
  .floating-header {
    margin-bottom: 0;

    .header-title {
      h2,
      p {
        margin-block-start: 0;
        margin-block-end: 0;
      }
    }

    select {
      margin-bottom: 0;
    }
  }

  .header-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 14px;
    flex-wrap: wrap;

    @media only screen and (max-width: 560px) {
      width: 100%;
    }

    input {
      margin-bottom: 0;
      margin-right: 10px;
    }

    input,
    select,
    button {
      @media only screen and (max-width: 560px) {
        width: 100%;
        margin-bottom: 15px;
        margin-right: 0;
      }
    }
  }

  .horizontal-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .item-pricing-container {
    tbody {
      tr {
        cursor: default;

        td {
          &:first-child {
            font-family: $font-family-medium;
          }
        }
      }
    }
  }

  .export-item-prices {
    display: flex;

    .action {
      width: 200px;
    }
  }
}