.contact-detail-page {
  a {
    color: #02a862;
    text-align: right;
    margin: 3px;
    padding: 0;
  }

  p {
    margin-left: 36px;
    margin-right: 36px;
  }

  .form-container .form-body {
    h4 {
      margin-left: 0;
    }

    > div {
      @media only screen and (max-width: 1980px) and (min-width: 1921px) {
        width: calc(25% - 25px);

        &:nth-child(4n) {
          margin-right: 0;
        }
      }

      @media only screen and (max-width: 1920px) and (min-width: 1241px) {
        width: calc(33% - 21px);

        &:nth-child(3n) {
          margin-right: 0;
        }
      }

      @media only screen and (max-width: 1240px) and (min-width: 1121px) {
        width: calc(33% - 11px);
        margin-right: 20px;

        &:nth-child(3n) {
          margin-right: 0;
        }
      }

      @media only screen and (max-width: 1120px) and (min-width: 800px) {
        width: calc(50% - 22px);
        margin-right: 20px;

        &:nth-child(2n) {
          margin-right: 0;
        }
      }

      @media only screen and (max-width: 800px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
      }
    }

    label,
    a {
      width: 100%;
    }

    span {
      width: 100px;
      display: inline-block;
    }
  }
}
